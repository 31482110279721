import { createContext, useEffect, useState } from 'react';

const AttendanceContext = createContext<any>([]);

const AttendanceContextProvider = ({children} : any) => {

    const [totalHours, setTotalHours] = useState<number[]>([]);
    const [currentNum, setCurrentNum] = useState<any>(0);
    const [lessonDuration, setLessonDuration] = useState<any>();
    const [hasStarted, setHasStarted] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [startTime, setStartTime] = useState<any>();
    const [endTime, setEndTime] = useState<any>();
    const [isJoined, setIsJoined] = useState<boolean>(false);

    const addTotalHours = (totalHour: number) => {
        const res = []
        for(let i = 0; i<=totalHour; i++){
            res.push(i)
        }
        setTotalHours(res);
    }

    useEffect(()=> {
        // if(startTime && endTime){
        //     const startTimeAsDate = convertToDateTime(startTime);
        //     const endTimeAsDate = convertToDateTime(endTime);
        //     if(new Date() > startTimeAsDate && new Date() < endTimeAsDate){
        //         setIsActive(true)
        //     } else{
        //         setIsActive(false)
        //     }
        // }
        if(startTime){
            const startTimeAsDate = convertToDateTime(startTime);
            if(new Date() > startTimeAsDate){
                setIsActive(true)
            } else{
                setIsActive(false)
            }
        }
    }, [startTime, isActive])

    useEffect(()=> {
        // if(startTime && endTime){
        //     const startTimeAsDate = convertToDateTime(startTime);
        //     const endTimeAsDate = convertToDateTime(endTime);
        //     if(new Date() > startTimeAsDate && new Date() < endTimeAsDate){
        //         setIsActive(true)
        //     } else{
        //         setIsActive(false)
        //     }
        // }
        if(endTime){
            const endTimeAsDate = convertToDateTime(endTime);
            if(new Date() > endTimeAsDate){
                setIsActive(false)
            } else{
                setIsActive(true)
            }
        }
    }, [endTime, isActive])

    const values = {
        addTotalHours,
        totalHours,
        lessonDuration,
        setLessonDuration,
        hasStarted,
        setHasStarted,
        currentNum,
        setCurrentNum,
        isActive,
        setIsActive,
        startTime,
        setStartTime,
        setEndTime,
        isJoined,
        setIsJoined
    };

    return <AttendanceContext.Provider value={values as any}>
        {children}
    </AttendanceContext.Provider>
}

export {AttendanceContext, AttendanceContextProvider};

function convertToDateTime(timeString : string) {
    const timeParts = timeString.split(':');
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const   
   seconds = parseFloat(timeParts[2]);
  
    // Create a default date (you can adjust this as needed)
    const defaultDate = new Date();
  
    // Set the time components on the default date
    defaultDate.setHours(hours);
    defaultDate.setMinutes(minutes);
    defaultDate.setSeconds(seconds);
    defaultDate.setMilliseconds(Math.round(seconds * 1000) % 1000); // Handle milliseconds
  
    return defaultDate;
  }
