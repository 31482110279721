import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material";

const sxTableCell: React.CSSProperties = {
  fontSize: "14px",
  fontFamily: 'Visby CF Bold, sans-serif',
  color: "#999898",
}

export interface ITableHeaderCellModel{
  onClick?: any,
  text: string,
  key: string,
  hasSort?: boolean,
  style?: any
}

interface ITableHeaderModel{
  cells: ITableHeaderCellModel[],
}

export default function Index({cells} : ITableHeaderModel) {
  return (
    <TableHead>
        <TableRow>
          {
            cells.map((elem, index)=> (
              <TableCell
                key={index}
                sx={sxTableCell}
                style={elem.style}
                onClick={elem.onClick}
                align="left"
              >
                {elem.text}
              </TableCell>
            ))
          }
        </TableRow>
    </TableHead>
    
  )
}
