import React, { useContext, useEffect, useState } from 'react';
import ModuleModal from '../module-modal';
import ModuleModalImage from '../../assets/moduleModal.svg';
import LessonHours from '../../assets/lessonHours.svg';
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import { useGetProgramModulesList } from '../../../../../../api/programs';
import {
  useEndAttendance,
  useJoinClass,
  useSaveAttendance,
  useStartAttendance,
} from '../../../../../../api/attendance';
import { AttendanceStudentsContext } from '../../../../../../context/AttendanceStudentsContext';
import Alert from '../../../../../../components/custom-components/alert';
import { AlertColor } from '@mui/material/Alert';
import PresenceModal from '../PresenceModal';
import { AttendanceContext } from '../../../../../../context/AttendanceContext';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { apiRoutes } from '../../../../../../apiRoutes';

function ModuleSelect({
  id,
  classId,
  workerId,
  classTimeSheetId,
  setAlertInside,
  setAlertOpen,
  setOpenModal
}: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isActive, setIsActive } = useContext(AttendanceContext);
  const [addInputs, setAddInputs] = useState([{ index: 0 }]);
  const [options, setOptions] = useState<any>([]);
  const { data: modules, isLoading } = useGetProgramModulesList(id);
  const mutation = useStartAttendance(
    classId,
    (oldData: any, newData: any) => newData
  );
  const endLessonMutation = useEndAttendance(
    classTimeSheetId,
    (oldData: any, newData: any) => newData
  );
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    const programs: any = [];
    (modules as any)?.modules?.forEach((elem: any) => {
      programs.push(elem);
      if (elem.subModules) {
        elem.subModules.forEach((subModl: any) => {
          programs.push(subModl);
        });
      }
    });
    setOptions(programs);
  }, [modules]);

  const handleLessonStart = async () => {
    try {
      const res = await mutation.mutateAsync({
        sessionCategory: 'Theoric',
        heldModules: addInputs?.map((elem: any) => ({ moduleId: elem.id })),
      });
      if (res.status === 204) {
        setIsActive(true);
      } else {
        setAlertInside({
          text: 'Dər artıq başladılıb',
          severity: 'error',
        });
        setAlertOpen(true);
      }
    } catch (err) {
      console.error(err);
    }finally{
      setOpenModal(false);
    }
  };

  const handleLessonFinish = async () => {
    try {
      const res = await endLessonMutation.mutateAsync({
        classTimeSheetId: classTimeSheetId,
      });
      setIsActive(false);
      setOpenModal(false);
      setAlertInside({
        text: 'Dər bitirildi!',
        severity: 'success',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleStartLab = async (isJoining: boolean) => {
    await handleLessonFinish();
    try {
      const res = await mutation.mutateAsync({
        sessionCategory: 'Lab',
        heldModules: [],
        isJoining: isJoining
      });
      if (res.status === 204) {
        setSearchParams({category: '1'});
      }
    } catch (err) {
      console.error(err);
    }
    setOpenDialog(false)
  };

  return (
    <div>
      {!isActive ? (
        <>
          <span
            style={{
              fontSize: '24px',
              fontWeight: '700',
              marginBottom: '18px',
              display: 'inline-block',
            }}
          >
            Modul seçin
          </span>
          {addInputs
            .sort((a, b) => (a as any).index - (b as any).index)
            .map((item, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                gap="8px"
                sx={{ mb: '18px' }}
              >
                <Autocomplete
                  sx={{ width: '100%' }}
                  value={item}
                  id={`grouped-demo-${index}`}
                  options={
                    options ? options : [{ label: 'test', name: 'test' }]
                  }
                  getOptionLabel={(option) =>
                    (option as any).name
                      ? `${(option as any).version} ${(option as any).name}`
                      : ''
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Modulu seçin və yazın"
                      sx={{ borderRadius: '12px' }}
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      <>
                        <li key={(option as any).id} {...props}>
                          {(option as any).version} {(option as any).name}
                        </li>
                      </>
                    );
                  }}
                  onChange={(event, item) => {
                    const inputs = addInputs.filter(
                      (elem) => (elem as any).index !== index
                    );
                    setAddInputs([...inputs, { ...item, index: index }]);
                  }}
                />
                <IconButton
                  onClick={() =>
                    setAddInputs(
                      addInputs.filter((item) => item.index !== index)
                    )
                  }
                  sx={{
                    display: addInputs.length > 1 ? 'block' : 'none',
                    padding: '0',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <img src="/icons/remove-recyecle.svg" />
                </IconButton>
              </Box>
            ))}
          <Typography
            onClick={() =>
              setAddInputs([...addInputs, { index: addInputs.length }])
            }
            style={{
              color: '#299AE8',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            component="span"
          >
            + Modul əlavə et
          </Typography>
        </>
      ) : null}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '100%' }}>
          {isActive ? (
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={() => handleLessonFinish()}
                variant="contained"
                className="saveBtn"
                sx={{
                  background: '#854693',
                  color: '#FFF',
                  padding: '8px 24px',
                  borderRadius: '12px',
                  fontFamily: 'Visby CF Bold, sans-serif',
                  textTransform: 'none',
                  mt: '21px',
                  mb: '12px',
                  width: '100%',
                  '&:hover': {
                    background: '#6e3d7d',
                  },
                }}
              >
                Dərsi bitir
              </Button>
              <span
                onClick={() => setOpenDialog(true)}
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#854693',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                Lab başlat
              </span>
            </div>
          ) : (
            <Button
              onClick={() => handleLessonStart()}
              disabled={addInputs.length === 1 && !(addInputs[0] as any).name}
              variant="contained"
              className="saveBtn"
              sx={{
                background: '#854693',
                color: '#FFF',
                padding: '8px 24px',
                borderRadius: '12px',
                fontFamily: 'Visby CF Bold, sans-serif',
                textTransform: 'none',
                mt: '21px',
                mb: '12px',
                width: '100%',
                '&:hover': {
                  background: '#6e3d7d',
                },
              }}
            >
              Dərsi başlat
            </Button>
          )}
          {/* <Typography
             onClick={() => console.log()}
             style={{color: '#854693', textDecoration: 'underline', cursor: 'pointer', display: 'block', textAlign: 'center'}}
             component="span"
              >
                Lab başlat
              </Typography> */}
        </div>
      </div>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Lab’da iştirak edəcəksinizmi? '}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => handleStartLab(false)}>Xeyr</Button>
          <Button 
            sx={{
              background: '#854693',
              color: '#FFF',
              padding: '8px 24px',
              borderRadius: '12px',
              fontFamily: 'Visby CF Bold, sans-serif',
              textTransform: 'none',
              mt: '21px',
              mb: '12px',
              width: '163px',
              '&:hover': {
                background: '#6e3d7d',
              },
            }}
            onClick={() => handleStartLab(true) }
          >
            Bəli
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function Mentor({ classTimeSheetId, setOpenModal }: any) {
  const { isActive, setIsActive, setIsJoined, isJoined } =
    useContext(AttendanceContext);
  const mutation = useJoinClass(
    classTimeSheetId,
    (oldData: any, newData: any) => newData
  );
  const handleLessonAttend = async () => {
    try {
      const res = await mutation.mutateAsync({});
      setIsActive(true);
      setIsJoined(true);
      setOpenModal(false);
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      {!isJoined ? (
        <Button
          onClick={() => handleLessonAttend()}
          variant="contained"
          className="saveBtn"
          disabled={!isActive}
          sx={{
            background: '#854693',
            color: '#FFF',
            padding: '8px 24px',
            borderRadius: '12px',
            fontFamily: 'Visby CF Bold, sans-serif',
            textTransform: 'none',
            mt: '21px',
            mb: '12px',
            width: '100%',
            '&:hover': {
              background: '#6e3d7d',
            },
          }}
        >
          Dərsə daxil ol
        </Button>
      ) : (
        <div>Siz artıq dərsdə iştirak edirsiz.</div>
      )}
    </>
  );
}

export default function Index({
  programId,
  teacher,
  mentor,
  classId,
  classTimeSheetId,
}: any) {
  const { students, isChangeInAttendance, setIsChangeInAttendance } =
    useContext(AttendanceStudentsContext);
  const user = useSelector((state: any) => state.auth.user);
  const { currentNum, isActive } = useContext(AttendanceContext);
  const mutation = useSaveAttendance(
    classTimeSheetId,
    (oldData: any, newData: any) => newData
  );
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [activeHoursOpenModal, setActiveHoursOpenModal] = useState<boolean>(false);
  const [alertInside, setAlertInside] = useState({
    text: '',
    severity: 'success' as AlertColor,
  });

  const handleSave = async () => {
    try {
      const res = await mutation.mutateAsync(
        students.map((st: any) => {
          return {
            studyId: st.studyId,
            note: st.note,
            totalAttendanceHours: st.totalHours ?? 0,
          };
        })
      );
      if (res.status) {
        setAlertInside({ text: 'Yadda saxlanildi', severity: 'success' });
        setAlertOpen(true);
        setIsChangeInAttendance(false);
      }
    } catch (err) {
      setAlertInside({ text: 'Xəta baş verdi', severity: 'error' });
      setAlertOpen(true);
    }
  };

  return (
    <div>
      <Typography sx={{ mb: '20px' }}>
        <span>
          <b style={{ fontWeight: 'bold' }}>Müəllim:</b> {teacher?.name}{' '}
          {teacher?.surname}
        </span>
        <br />
        <span>
          <b style={{ fontWeight: 'bold' }}>Mentor: </b>
          {mentor?.name} {mentor?.surname}
        </span>
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end',
        }}
      >
        <div style={{ display: 'flex' }}>
          <ModuleModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            cardModel={{
              image: ModuleModalImage,
              title: 'Dərs qeydiyyatı',
              subtitle: '',
              color: '#C6DBEA',
            }}
            title=""
            width={490}
            children={
              user?.roles?.find((x: any) => x === 'Müəllim') ? (
                <ModuleSelect
                  setOpenModal={setOpenModal}
                  workerId={teacher?.id}
                  id={programId}
                  classId={classId}
                  classTimeSheetId={classTimeSheetId}
                  setAlertOpen={setAlertOpen}
                  setAlertInside={setAlertInside}
                />
              ) : user?.roles?.find((x: any) => x === 'Mentor') ? (
                <Mentor setOpenModal={setOpenModal} classTimeSheetId={classTimeSheetId} />
              ) : null
            }
          />
          <ModuleModal
            openModal={activeHoursOpenModal}
            setOpenModal={setActiveHoursOpenModal}
            cardModel={{
              image: LessonHours,
              title: 'Aktivlik',
              subtitle: `İştirak saatı: ${currentNum}`,
              color: '#C7E3CB',
            }}
            title=""
            width={232}
            isSelect
            children={<PresenceModal setOpenModal={setActiveHoursOpenModal} />}
          />
        </div>
        <Tooltip
          title={
            !isActive
              ? 'Dərs aktiv deyil!'
              : !isChangeInAttendance
              ? 'Davamiyyət daxil edin!'
              : ''
          }
        >
          <span>
            <Button
              variant="contained"
              className="saveBtn"
              onClick={handleSave}
              disabled={
                (user?.roles?.find((x: any) => x === 'Müəllim') &&
                  (!isActive || !isChangeInAttendance)) ||
                (user?.roles?.find((x: any) => x === 'Mentor') &&
                  !mentor.isJoined &&
                  !isChangeInAttendance)
              }
              sx={{
                background: '#854693',
                color: '#FFF',
                padding: '8px 24px',
                borderRadius: '12px',
                fontFamily: 'Visby CF Bold, sans-serif',
                textTransform: 'none',
                mt: '21px',
                '&:hover': {
                  background: '#6e3d7d',
                },
              }}
            >
              Təsdiq et
            </Button>
          </span>
        </Tooltip>
      </div>
      {alertOpen && (
        <Alert
          open={alertOpen}
          text={alertInside.text}
          severity={alertInside.severity}
          handleClose={() => setAlertOpen(false)}
        />
      )}
    </div>
  );
}
