import { useState, forwardRef } from "react";
import {
  Autocomplete,
  TextField,
  createFilterOptions,
  Checkbox,
  Typography,
} from "@mui/material";
import { BaseService } from "../../../network/services/base/BaseService";
import { Controller } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option: WorkerModel) => `${option.name} ${option.surname}`,
});

interface WorkerModel {
  name: string;
  surname: string;
}

export interface ICustomAutocompleteModel {
  label: string;
  url?: string;
  customData?: any;
  propName: string;
  defaultValue?: any;
  sx?: any;
  onChange?: any;
  value?: any;
  selectedProp?: any;
  control?: any;
  errors?: boolean;
  disable?: boolean;
  noOptionText?: string;
  workerType?: string;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomMultipleAutocomplete = forwardRef(function CustomAutocomplete(
  {
    disable = false,
    control,
    label,
    url,
    value,
    customData,
    propName,
    defaultValue,
    onChange,
    sx,
    selectedProp,
    errors,
    noOptionText,
    workerType,
  }: ICustomAutocompleteModel,
  ref: React.Ref<unknown>
) {
  const [data, setData] = useState(customData ? customData : []);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const service = new BaseService(`api/`);
      const res = await service.getAll(`api/${url}`);
      if (res.statusCode === 200) {
        setData(res.data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return control !== undefined ? (
    <Controller
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          multiple // Enable multiple selection
          value={value || []} // Ensure the value is an array
          options={data} // Options for the dropdown
          filterOptions={filterOptions}
          loading={loading}
          disableCloseOnSelect // Keep the dropdown open after selecting an option
          onChange={(e, selectedValues) => onChange(selectedValues)} // Pass the array of selected values
          onOpen={() => (url ? fetchData() : null)}
          getOptionLabel={(option) =>
            option.name && option.surname
              ? `${option.name} ${option.surname}`
              : ""
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {`${option.name} ${option.surname}`}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={`${
                workerType === "Teacher" ? "Müəllimlər" : "Mentorlar"
              } Seçin`}
            />
          )}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: 4,
            },
          }}
          fullWidth
        />
      )}
      defaultValue={defaultValue}
      name={selectedProp}
      control={control}
    />
  ) : (
    <Autocomplete
      multiple
      defaultValue={defaultValue}
      value={value}
      loading={loading}
      options={data}
      disableCloseOnSelect
      filterOptions={filterOptions}
      onChange={(e, v) => onChange(v)}
      onOpen={() => (url ? fetchData() : null)}
      getOptionLabel={(option) =>
        option.name && option.surname ? `${option.name} ${option.surname}` : ""
      }
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          style={{
            pointerEvents: value.length >= 2 && !selected ? "none" : "auto",
            opacity: value.length >= 2 && !selected ? 0.5 : 1,
          }}
        >
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
            disabled={value.length >= 2 && !selected} // 2'den fazla seçime izin verme
          />
          {`${option.name} ${option.surname}`}
        </li>
      )}
      slotProps={{
        paper: {
          sx: {
            padding: "0px!important",
            marginTop: "0px!important",
            borderRadius: "0px!important",
          },
        },
      }}
      sx={
        sx
          ? sx
          : {
              "& .MuiOutlinedInput-root": {
                // border: "1px solid yellow",
                borderRadius: "0",
                padding: "0",
              },
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }
      }
      renderTags={(selectedValues) => (
        <div
          style={{
            maxHeight: "60px", // Sabit yükseklik
            overflowY: "auto", // Kaydırma çubuğu
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {selectedValues.map((value, index) => (
            <Typography
              key={index}
              sx={{
                fontSize: "14px",
                fontFamily: "Visby CF Bold, sans-serif",
                margin: "0", // Margin değerlerini sıfırla
                marginTop: "5px",
                marginLeft: "15px",
                lineHeight: "20px", // Satır yüksekliğini ayarla
              }}
            >
              {value.name} {value.surname}
            </Typography>
          ))}
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            placeholder:
              workerType === "Teacher" ? "Müəllimlər Seçin" : "Mentorlar Seçin",
            style: {
              padding: "calc(0.5vw + 5px)",
              fontSize: "calc(0.5vw + 5px)",
              fontFamily: "Visby CF Bold, sans-serif",
              // border: "1px solid red"
            },
          }}
        />
      )}
      fullWidth
    />
  );
});

export default CustomMultipleAutocomplete;
