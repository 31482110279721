import {
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import Header from "./header";
import AddTableRow from "./add-module-row";
import Row from "./row";
import CustomDatePicker from "../date-picker";
import { BaseService } from "../../../../../../../network/services/base/BaseService";
import CustomMultipleAutocomplete from "../../../../../../../components/custom-components/custom-multuple-autocomplete";

interface ISetProgramTableModel {
  modules: any;
  setModules: any;
  customSX?: any;
  setLoading: any;
  classId?: any;
  headerCells: any;
  hasCheckBox: boolean;
}
const classService = new BaseService("");
export default function Index({
  modules,
  setModules,
  customSX,
  setLoading,
  classId,
  headerCells,
  hasCheckBox
}: ISetProgramTableModel) {
  const handleStartAndEndDateChange = (
    val: any,
    key: any,
    startDate?: any,
    endDate?: any,
    workerType?: any
  ) => {
    setLoading(true);
    classService
      .getAll(
        `api/Classes/${classId}/generate-assign-workers?${
          startDate ? `startDate=${startDate}&` : ""  
        }${endDate ? `endDate=${val}&` : ""}${
          workerType ? `workerType=${workerType}` : ""
        }`
      )
      .then(({ data }: any) => {
        setModules((prevModules: any) => {
          // Find the module to update
          const updatedModules = prevModules.map((elem: any) =>
            elem.startDate === startDate &&
            elem.endDate === endDate &&
            elem.workerType === workerType
              ? { ...elem, [key]: val, totalHours: data.totalHours }
              : elem
          );
          // Merge or append new data from response
          const newModules = data.generateWorker; // Assuming `modules` is the key in the response for new data
          return [newModules,...updatedModules ];
        });
      })
      .catch((err: any) => console.log(err))
      .finally(() => setLoading(false));
  };
  const handleTeacherAndMentorChange = (
    selectedWorkers: any[], // Dropdown'da seçilen kullanıcılar
    startDate?: any,
    endDate?: any,
    workerType?: any
  ) => {
    setModules((prevModules: any) =>
      prevModules.map((module: any) => {
        if (
          module.startDate === startDate &&
          module.endDate === endDate &&
          module.workerType === workerType
        ) {
          return {
            ...module,
            workers: selectedWorkers, // Yalnızca yeni seçilen kullanıcıları ayarla
          };
        }
        return module;
      })
    );
  };
   return (
    <TableContainer sx={customSX}>
      <Table
        sx={{
          width: "100%",
          border: "none",
        }}
        aria-label="simple table"
      >
        <Header cells={headerCells} />
        <TableBody>
          {modules.map((module: any, index: any) => (
            <Fragment key={index}>
              <Row
                hasCheckBox={hasCheckBox}
                cells={headerCells.map((key:any, index:number) => {
                  if (key.key === "startDate" || key.key === "endDate") {
                    return {
                      key: key.key,
                      text: module[key?.key],
                      isCustomelement: true,
                      customElement: (
                        <CustomDatePicker
                          isOpen={key.key === "startDate" ? true : false}
                          date={module[key?.key]}
                          handleDateChange={(e) =>
                            handleStartAndEndDateChange(
                              e,
                              key.key,
                              module.startDate,
                              module.endDate,
                              module.workerType
                            )
                          }
                          type="date"
                        />
                      ),
                    };
                  } else if (key.key === "workers") {
                    return {
                      key: key.key,
                      isCustomelement: true,
                      customElement: (
                        <CustomMultipleAutocomplete
                        workerType={module.workerType || []}
                        value={module.workers} // Pass the array of selected workers
                        label={""}
                        url={`Workers?workerType=${module.workerType}`}
                        propName="name"
                        onChange={(selectedWorkers: any[]) =>
                          handleTeacherAndMentorChange(
                            selectedWorkers,
                            module.startDate,
                            module.endDate,
                            module.workerType
                          )
                        }
                      />
                      
                      ),
                    };
                  }
                  return {
                    key: key.key,
                    text: module[key?.key],
                  };
                })}
              />
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

