import LessonPage from '../LessonPage';
import LabPage from '../LabPage';
import { useGetSessionClassById } from '../../../../../../api/classes';
import StudentsTable from '../StudentsTable';
import { useEffect, useState, useContext } from 'react';
import { AttendanceStudentsContext } from '../../../../../../context/AttendanceStudentsContext';
import { AttendanceContext } from '../../../../../../context/AttendanceContext';
import Progress from '../../../../../../components/custom-components/progress';

export default function Index({tabValue, currentClass}: any) {

    const {data: session, isLoading} = useGetSessionClassById(currentClass.id, tabValue === 0 ? 'Theoric' : 'Lab');
    const {students, setStudents, setOriginalData} = useContext(AttendanceStudentsContext);
    const {addTotalHours, setStartTime, setEndTime, setIsJoined} = useContext(AttendanceContext);

    useEffect(()=> {
        if(session){
            setStudents(session.studyAttendances?.map((student: any)=> {return {...student, note: student.note, totalHours: student.totalHours, isChecked: false}}));
            setOriginalData(session.studyAttendances?.map((student: any)=> {return {...student, note: student.note, totalHours: student.totalHours, isChecked: false}}));
            addTotalHours(session.sessionTotalHours);
            setStartTime(session.startTime);
            setEndTime(session.endTime);
            setIsJoined(session.workers?.find((x: any)=> x.workerType === "Mentor")?.isJoined);
        }
    }, [session]);

  return (
    <div>
        {
            isLoading ? (
                <Progress/>
            ) :
            session ?
            (
                <>
                    {
                        tabValue === 0 ? (
                            <>
                            {
                                session?.category !== "Theoric" ? (
                                    <span>No lesson for today</span>
                                ) : (
                                    <>
                                        <div style={{marginBottom: '30px'}}>
                                            <LessonPage programId={currentClass.programId} mentor={session?.workers?.find((x: any)=> x.workerType === "Mentor")} teacher={session?.workers?.find((x: any)=> x.workerType === "Teacher")} classId={currentClass.id} classTimeSheetId={session?.classTimeSheetId}/>
                                        </div>
                                        <StudentsTable type={'Theory'} students={students}/>
                                    </>
                                )
                            }
                            </>
                        ) : (
                            <>
                            {
                                session?.category !== "Lab" ? (
                                    <span>No lab for today</span>
                                ) : (
                                    <>
                                    <div style={{marginBottom: '30px'}}>
                                        <LabPage isLessonActive={!session.canStarted} classId={currentClass.id} mentor={session?.workers?.find((x: any)=> x.workerType === "Mentor")} teacher={session?.workers?.find((x: any)=> x.workerType === "Teacher")} classTimeSheetId={session?.classTimeSheetId}/>
                                    </div>
                                    <StudentsTable type={'Lab.'} students={students}/>
                                    </>
                                 )
                            }
                                
                            </>
                        )
                    }
                </>
            ) : (
                <div>...</div>
            )
        }
        
    </div>
  )
}
