import { myLeaveRequestsView } from "../../../../../../../permissions";
import { UserHasPermission } from "../../../../../../../utils/hooks/userPermissions";



export const datagridSx = {    
  
  

    '& .MuiDataGrid':
    {
    border:'none',
    zIndex:0
    },
    
    '& .MuiDataGrid-root': {
      border: 'none', 
    },
    '& .MuiDataGrid-cell': {
      borderBottom: '1px solid var(--Natural-natural-100, #BABABA)', 
    fontFamily: 'Visby CF Bold',
    fontSize: '16px',
    background:"#FFFFFF",
    padding:'0px 0px 0px 50px',
    
  
    
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#F3EDF496', 
    borderBottom: 'none', 
    fontSize: '16px',
    fontFamily: 'Visby CF Bold',
    zIndex:"0",
    
      
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: '16px',
      fontFamily: 'Visby CF Bold',
      border: 'none',
      padding:'0px 0px 0px 90px',
      
      // marginLeft: "30px"
      
    },
    '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader[data-field="enddate"] .MuiDataGrid-columnHeaderTitle': {
    padding: '0px 0px 0px 40px', 
  },
  
    '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader[data-field="status"] .MuiDataGrid-columnHeaderTitle': {
    padding: '0px 0px 0px 50px', 
  },
  '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader[data-field="startdate"] .MuiDataGrid-columnHeaderTitle': {
    padding: '0px 0px 0px 90px',
  },
  '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader[data-field="title"] .MuiDataGrid-columnHeaderTitle': {
    padding: '0px 0px 0px 60px',
  },
    '& .MuiDataGrid-footerContainer': {
        border: 'none',
        right: '100px'
      
    },
    '& .MuiDataGrid-sortIcon': {
        border: 'none',
      display: 'none', 
    },
    '& .MuiDataGrid-virtualScroller': {
        
      border: 'none', 
      paddingBottom: '20px', 
    },
    '& .MuiDataGrid-row': {
       
      minHeight: '84px'
    },
    '& .MuiDataGrid-columnSeparator': {
    display: 'none',  
  },
  '& .MuiDataGrid-toolbarContainer': {
    padding: '20px', 
  },
  ".MuiDataGrid-columnHeader:focus,.MuiDataGrid-cell:focus": {
        outline: "none"
    },
  
  };
  