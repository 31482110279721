import {
    useDelete, 
      useFetch, usePost, useUpdate 
   } from "../utils/api/reactQuery";
import { apiRoutes } from "../apiRoutes";
import { ActionModel, CommentModel, Files, RequestAction, RequestModel, RequestTypeModel, SubstituteTeacher } from "../pages/app-views/LeaveRequests/Teacher/models";

export const useGetTypesList = () =>
    useFetch<any[]>(apiRoutes.leaverequests.getAll, undefined, [apiRoutes.leaverequests.base, undefined]);

export const usePostRequest = () =>
    usePost<any,RequestModel>(apiRoutes.leaverequests.addRequest,undefined);

export const useGetRequests = (isCurrent?: boolean) =>
    useFetch<any[]>(
        `${apiRoutes.leaverequests.myRequests}${isCurrent !== undefined ? `?isCurrent=${isCurrent}` : ''}`,
        [apiRoutes.leaverequests.base, isCurrent], ['', undefined], false
    );

export const useGetMyRequests = () => 
    useFetch<any[]>(
    `${apiRoutes.leaverequests.myRequests}`, {}, ['', undefined], false
    );

export const useGetAllRequests = () =>
    useFetch<any[]>(
        `${apiRoutes.leaverequests.getAllRequests}`, {}, [apiRoutes.leaverequests.getAllRequests, undefined], false
    );


export const useGetCommentByID = (id: number) =>
    useFetch<any>(apiRoutes.leaverequests.getComments(id));

export const usePostComment = (id:number) =>
    usePost<any,CommentModel>(apiRoutes.leaverequests.addComment(id),undefined);

export const useGetRequest = (id:number) =>
    useFetch<any>(apiRoutes.leaverequests.getRequest(id));

export const useGetRequestActionSteps = (id:number) =>
    useFetch<any>(apiRoutes.leaverequests.getActionSteps(id));

export const usePostAction = (id: number) =>
    usePost<any,ActionModel>(apiRoutes.leaverequests.postAction(id),undefined);

export const useOpenAction = (id: number) =>
    usePost<any,ActionModel>(apiRoutes.leaverequests.open(id),undefined);

export const usePostFile = (id: number) =>
    usePost<any,Files>(apiRoutes.leaverequests.postFile(id),undefined);

export const useGetActionHistories = (id: number) =>
    useFetch<RequestAction>(apiRoutes.leaverequests.getActionHistories(id));

export const useGetAllRequestes = () =>
    useFetch<any>(apiRoutes.leaverequests.getAllRequestes,undefined,[apiRoutes.leaverequests.base,undefined]);

export const useGetActionReasons = (action: any) => {
    const url = `${apiRoutes.leaverequests.actionReasons}?action=${action}`;
    return useFetch<any>(url, undefined, [apiRoutes.leaverequests.base, action], true);
  };

export const useGetSubstituteTeachers = (id: number) =>
    useFetch<SubstituteTeacher[]> (apiRoutes.leaverequests.Teachers(id))