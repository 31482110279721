import { createContext, useEffect, useState } from 'react';

const AttendanceStudentsContext = createContext<any>([]);

const AttendanceStudentsContextProvider = ({children} : any) => {
    const [students, setStudents] = useState<any>([]);
    const [originalData, setOriginalData] = useState<any>([]);
    const [areAllChecked, setAreAllChecked] = useState<boolean>(false);
    const [isChangeInAttendance, setIsChangeInAttendance] = useState<boolean>(false);

    const handleStudentChecked = (studentId: any) => {
        setStudents(students.map((student: any)=> {
            if(student.studyId === studentId){
                return {...student, isChecked: !student.isChecked}
            }else{
                return student
            }
        }));
    }

    const handleStudentNoteAdd = (studentId : any, note: string ) => {
        setStudents(students.map((student: any)=> {
            if(student.studyId === studentId){
                return {...student, note: note}
            }else{
                return student
            }
        }));
    }

    const handleStudentTotalHoursChange = (studentId: any, totalHours: any) => {
        setStudents(students.map((student: any)=> {
            if(student.studyId === studentId){
                return {...student, totalHours: totalHours}
            }
            else{
                return student
            }
        }))
    }

    const handleAllStudentsCheck = (check : any, totalHour: number) => {
        if(originalData?.some((elem:any)=> elem.totalHours === null)){
            setStudents(students.map((student: any)=> {return {...student, isChecked: check, totalHours: totalHour}}));
        }else{
            setStudents(students.map((student: any)=> {return {...student, isChecked: check}}));
        }
    }

    const handleSelectedStudentsAttendanceHours = (totalHours: any) => {
        setStudents(students.map((student: any) => student.isChecked ? {...student, totalHours: totalHours} : student));
    }

    useEffect(()=> {
        if(students?.some((x: any)=> !x.isChecked)){
            setAreAllChecked(false);
        }else{
            setAreAllChecked(true)
        }
    }, [students])

    useEffect(()=> {
        let isChange = false;
        students.forEach((student: any) => {
            const origin = originalData.find((x: any)=> x.studyId === student.studyId);
            if(origin?.totalHours !== student.totalHours){
                isChange = true;
                return;
            }else if( origin?.note !== student.note){
                isChange = true;
                return;
            }
        })
        setIsChangeInAttendance(isChange);
    }, [students])

    const values = {
        students, 
        setStudents,
        handleStudentChecked,
        handleStudentNoteAdd,
        handleStudentTotalHoursChange,
        handleAllStudentsCheck,
        areAllChecked,
        handleSelectedStudentsAttendanceHours,
        isChangeInAttendance,
        setIsChangeInAttendance,
        originalData,
        setOriginalData
    }

    return <AttendanceStudentsContext.Provider value={values as any}>
        {children}
    </AttendanceStudentsContext.Provider>
}

export {AttendanceStudentsContext, AttendanceStudentsContextProvider};