import {ITableHeaderCellModel} from './header';

export const headerCells : ITableHeaderCellModel[] = [
    {
        text: 'Başlama tarixi',
        key: 'startDate'
    },
    {
        text: 'Bitmə tarixi',
        key: 'endDate'
    },
    {
        text: 'Ümumi saat',
        key: 'totalHours'
    },
    {
        text: 'Ad, Soyad',
        key: 'workers'
    },
    {
        text: 'Vəzifə',
        key: 'workerType'
    },
]