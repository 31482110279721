import { Box } from '@mui/system';
import React, { useRef, useState } from 'react';
import { Typography } from '@mui/material';
import styles from '../modal.module.css';
import {
  calculateDateRange,
  formatDate,
} from '../../../../../../../../../utils/dateFunctions';
import {
  useGetActionHistories,
  useGetCommentByID,
  usePostAction,
  usePostComment,
  usePostFile,
} from '../../../../../../../../../api/requests';
import { UserHasPermission } from '../../../../../../../../../utils/hooks/userPermissions';
import {
  canActionRequest,
  canAddFile,
} from '../../../../../../../../../permissions';
import pdfimage from '../../../../images/pdff.png';
import docximage from '../../../../images/docx.png';
import jpgimage from '../../../../images/jpg.png';
import Button from '../../../../../../../../../components/core-components/inputs/button';
import Footer from '../../components/footer';
import TabContent from '../tab';
import moment from 'moment';
import { TextField } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { apiRoutes } from '../../../../../../../../../apiRoutes';

export default function Index({
  request,
  setStage,
  setIsApprove,
  onClose,
}: any) {
  const userRole = useSelector((state: any) => state.auth.user.roles[0]);
  const postActionMutation = usePostAction(request.id);
  const mutation = usePostFile(request.id);
  const userHasActionPermissions = UserHasPermission(canActionRequest);
  const { data: histories = [] } = useGetActionHistories(request.id);
  const hasCanAddFilePermission = UserHasPermission(canAddFile);
  const { data: comments = [], isLoading: isCommentsLoading } =
    useGetCommentByID(request.id);
  const postCommentMutation = usePostComment(request.id);
  const queryClient = useQueryClient();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [value, setValue] = useState(0);
  const [comment, setComment] = useState<string>('');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      try {
        const formDataToSubmit = new FormData();
        formDataToSubmit.append('file', event.target.files[0]);
        const res = mutation.mutateAsync(formDataToSubmit);
        //   props.setPopup(true)
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleCommentSubmit = async () => {
    if (comment.trim() === '') {
      console.error('Bos.');
      return;
    }

    const formDataToSubmit = {
      content: comment,
    };

    try {
      await postCommentMutation.mutateAsync(formDataToSubmit, {
        onSuccess: () => {
          queryClient.invalidateQueries(['comments', request.id]);
          setComment('');
        },
        onError: (error) => {
          console.error('error:', error);
        },
      });
    } catch (error) {
      console.error('Sending comment failed:', error);
    }
  };

  const triggerFileInput = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleReject = async () => {
    if (request.isRejectHasReason) {
      setStage(2);
    } else if (userRole === 'Student Office') {
      setStage(3);
      setIsApprove(false);
    } else {
      try {
        const res = await postActionMutation.mutateAsync({
          reasonId: null,
          reasonNote: '',
          substituteTeacherId: null,
          status: 'Reject',
        });
        queryClient.invalidateQueries(apiRoutes.leaverequests.myRequests);
        queryClient.invalidateQueries(apiRoutes.leaverequests.getAllRequests);
        queryClient.invalidateQueries(
          apiRoutes.leaverequests.getRequest(request.id)
        );
        onClose();
      } catch (err) {}
    }
  };

  const handleApprove = async () => {
    if (request.isApproveHasReason) {
      setStage(1);
    } else if (userRole === 'Student Office') {
      setStage(3);
      setIsApprove(true);
    } else {
      try {
        const res = await postActionMutation.mutateAsync({
          reasonId: null,
          reasonNote: '',
          substituteTeacherId: null,
          status: 'Approve',
        });
        queryClient.invalidateQueries(apiRoutes.leaverequests.myRequests);
        queryClient.invalidateQueries(apiRoutes.leaverequests.getAllRequests);
        queryClient.invalidateQueries(
          apiRoutes.leaverequests.getRequest(request.id)
        );
        onClose();
      } catch (err) {}
    }
  };

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M3.3335 13.3333V12.6667C3.3335 10.0893 5.42284 8 8.00016 8V8C10.5775 8 12.6668 10.0893 12.6668 12.6667V13.3333"
            stroke="#999898"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.00016 8.00008C9.47292 8.00008 10.6668 6.80617 10.6668 5.33341C10.6668 3.86065 9.47292 2.66675 8.00016 2.66675C6.5274 2.66675 5.3335 3.86065 5.3335 5.33341C5.3335 6.80617 6.5274 8.00008 8.00016 8.00008Z"
            stroke="#999898"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <Typography
          sx={{
            marginLeft: '10px',
            color: 'var(--Natural-natural-200, #999898)',
          }}
        >
          Status:
        </Typography>
        <Box
          sx={{ marginLeft: '10px' }}
          className={`${styles.status} ${
            request?.status === 'Approved'
              ? styles.statusapply
              : request?.status === 'Pending'
              ? styles.statuswait
              : request?.status === 'Rejected'
              ? styles.statusreject
              : styles.statusunknown
          }`}
        >
          {request?.status === 'Approved'
            ? 'Təsdiqlənib'
            : request?.status === 'Pending'
            ? 'Gözləmədə'
            : request?.status === 'Rejected'
            ? 'Qəbul edilməyib'
            : 'Naməlum status'}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
            stroke="#999898"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z"
            stroke="#999898"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.6665 4H10.6665C11.0201 4 11.3593 4.14048 11.6093 4.39052C11.8594 4.64057 11.9998 4.97971 11.9998 5.33333V10"
            stroke="#999898"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 6V14"
            stroke="#999898"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <Typography
          sx={{
            marginLeft: '10px',
            color: 'var(--Natural-natural-200, #999898)',
          }}
        >
          İcazə başlığı:
        </Typography>
        <Box
          sx={{
            height: 'Hug(24px)px',
            padding: '4px 8px',
            width: 'auto',
            marginLeft: '20px',
          }}
        >
          <Typography
            sx={{
              fontSize: '15px',
              color: 'black',
              fontFamily: 'Visby CF Bold',
            }}
          >
            {request?.title}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.75 0C5.16421 0 5.5 0.335786 5.5 0.75V2H10.5V0.75C10.5 0.335786 10.8358 0 11.25 0C11.6642 0 12 0.335786 12 0.75V2H13.25C14.2165 2 15 2.7835 15 3.75V14.25C15 15.2165 14.2165 16 13.25 16H2.75C1.7835 16 1 15.2165 1 14.25V3.75C1 2.7835 1.7835 2 2.75 2H4V0.75C4 0.335786 4.33579 0 4.75 0ZM13.25 3.5C13.3881 3.5 13.5 3.61193 13.5 3.75V6H2.5V3.75C2.5 3.61193 2.61193 3.5 2.75 3.5H13.25ZM2.5 7.5V14.25C2.5 14.3881 2.61193 14.5 2.75 14.5H13.25C13.3881 14.5 13.5 14.3881 13.5 14.25V7.5H2.5Z"
            fill="#999898"
          />
        </svg>
        <Typography
          sx={{
            marginLeft: '10px',
            color: 'var(--Natural-natural-200, #999898)',
          }}
        >
          İcazə tarixi:
        </Typography>
        <Box sx={{ marginLeft: '40px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'Visby CF Bold',
              color: '#4F5259',
            }}
          >
            {`${formatDate(request?.startDate)} - ${formatDate(
              request?.endDate
            )} · ${
              calculateDateRange(request?.startDate, request?.endDate) + 1
            } gün`}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Typography
          sx={{
            color: 'var(--Natural-natural-200, #999898)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M8.632 4.87189L4.94667 8.55656C4.62063 8.88268 4.4375 9.32497 4.43756 9.78613C4.43762 10.2473 4.62088 10.6895 4.947 11.0156C5.27313 11.3416 5.71542 11.5247 6.17657 11.5247C6.63772 11.5246 7.07996 11.3414 7.406 11.0152L11.394 7.02722C11.6776 6.74543 11.9026 6.41035 12.0562 6.04125C12.2097 5.67215 12.2888 5.27633 12.2888 4.87656C12.2888 4.47679 12.2097 4.08096 12.0562 3.71187C11.9026 3.34277 11.6776 3.00768 11.394 2.72589C10.8205 2.16963 10.0507 1.86216 9.25176 1.87027C8.45285 1.87839 7.68942 2.20143 7.12734 2.76922L3.10467 6.71456C2.31744 7.53478 1.88307 8.63089 1.89476 9.76771C1.90646 10.9045 2.36328 11.9915 3.16722 12.7953C3.97116 13.5992 5.05816 14.0559 6.19498 14.0675C7.3318 14.079 8.42787 13.6445 9.248 12.8572L14.166 7.98256"
              stroke="#999898"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Sənəd:
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '12px',
              width:
                request?.files.length === 0
                  ? '80px'
                  : request?.files.length === 1
                  ? '200px'
                  : '390px',
            }}
          >
            {request?.files && request?.files.length > 0
              ? request?.files.map(
                  (
                    file: {
                      name: string;
                      size: string;
                      path: string;
                      extension: string;
                    },
                    index: number
                  ) => (
                    <Box
                      key={index}
                      sx={{
                        marginLeft: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                      }}
                    >
                      <Box
                        sx={{
                          width: '178px',
                          height: '60px',
                          borderRadius: '4px',
                          border:
                            '1px solid var(--Natural-natural-25, #F3F3F3)',
                          alignItems: 'center',
                          justifyContent: 'center',
                          justifyItems: 'center',
                          display: 'flex',
                          gap: '8px',
                        }}
                      >
                        {' '}
                        <Box>
                          <img
                            style={{ height: '32px' }}
                            src={
                              file.extension === '.pdf'
                                ? pdfimage
                                : file.extension === '.doc' ||
                                  file.extension === '.docx'
                                ? docximage
                                : ['.png', '.jpg', '.jpeg'].includes(
                                    file.extension
                                  )
                                ? jpgimage
                                : ''
                            }
                          />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              color: '#4E4C4D',
                              fontSize: '12px',
                              maxWidth: '100px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {file.name
                              .replace(
                                /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}/,
                                ''
                              )
                              ?.replace(
                                /\.\d{4}-\d{2}-\d{2}--\d{2}-\d{2}-\d{2}/,
                                ''
                              )
                              .trim()}
                          </Typography>
                          <Typography>
                            <span
                              color="#6B696A"
                              style={{ fontSize: '10px', color: '#6B696A' }}
                            >
                              {(Number(file.size) / 1048576).toFixed(2)} MB
                            </span>
                            <span
                              style={{ fontSize: '10px', color: '#6B696A' }}
                            >
                              {' '}
                              -{' '}
                            </span>
                            <span
                              onClick={() => window.open(file.path, '_blank')}
                              style={{
                                cursor: 'pointer',
                                fontFamily: 'Visby CF Bold',
                                fontSize: '10px',
                                color: '#854693',
                                textDecoration: 'underline',
                              }}
                            >
                              Yüklə
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )
                )
              : null}
          </div>
          {hasCanAddFilePermission ? (
            <div>
              <Box
                onClick={triggerFileInput}
                sx={{
                  cursor: 'pointer',
                  height: '56px',
                  width: '56px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '12px',
                  gap: '8px',
                  display: 'flex',
                  borderRadius: '4px',
                  border: ' 1px solid var(--Primary-primary-50, #F3EDF4)',
                  backgroundColor: '#F8F6F9',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 5V19"
                    stroke="#854693"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 12H19"
                    stroke="#854693"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Box>{' '}
              <input
                type="file"
                ref={inputRef}
                onChange={handleFileUpload}
                style={{ display: 'none' }}
              />{' '}
            </div>
          ) : null}
        </Box>
      </Box>

      {request
        ? getNotification({
            reason: request?.leaveRequestActionReason,
            reasonNote: request?.reasonNote,
            subtituteTeacher: request?.substituteWorker,
            isApprove: request?.status === 'Approved',
            isReject: request?.status === 'Rejected',
            isStudentPermission: (userRole === 'Student Office' || userRole === 'Tələbə'),
            isOnline: request?.isOnline
          })
        : null}

      <TabContent
        value={value}
        onChange={handleChange}
        actions={
          (histories as any)?.map((history: any) => ({
            id: history.id,
            desc: history.content,
            date: moment(history.createdDate).format('DD MMM YYYY, HH:mm'),
            name: `${history.actionByUser.name} ${history.actionByUser.surname}`,
          })) || []
        }
        comments={
          Array.isArray(comments)
            ? comments?.map((comment: any) => ({
                id: comment.id,
                desc: comment.content,
                date: moment(comment.createdDate).format('DD MMM YYYY | HH:mm'),
                name: `${comment.name} ${comment.surname}`,
              }))
            : []
        }
      />
      <Footer createdDate={request?.createdDate}>
        <Box
          sx={{ width: '100%', marginBottom: '12px', marginTop: '12px' }}
          className={`${styles.addcomment}`}
        >
          <Box className={`${styles.image}`}></Box>

          <TextField
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            variant="outlined"
            placeholder="Rəy yazın..."
            InputProps={{
              disableUnderline: true,
              sx: {
                fontSize: '14px',
                fontFamily: 'Visby CF Regular',
                color: '#999898',
                flex: '1 0 0',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              },
            }}
            sx={{
              width: '100%',
              '& .MuiInputBase-root': {
                paddingLeft: '0',
              },
            }}
          />

          <div>
            <svg
              onClick={handleCommentSubmit}
              style={{ cursor: 'pointer' }}
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
            >
              <path
                d="M25.3309 12.0118C25.3402 11.2951 24.952 10.6364 24.3215 10.2907L9.62962 2.21852C8.97157 1.8466 8.19584 1.8923 7.57577 2.31572C6.94431 2.74627 6.62111 3.77121 6.79763 4.51148L8.16918 10.2577C8.30998 10.847 8.83734 11.2619 9.44405 11.2593L17.62 11.2339C18.0387 11.2255 18.3783 11.5651 18.3699 11.9838C18.3686 12.3953 18.0341 12.7299 17.6154 12.7383L9.43159 12.7573C8.82489 12.7584 8.29495 13.1752 8.15049 13.7653L6.73044 19.5344C6.55802 20.2162 6.7546 20.8968 7.23563 21.3778C7.29222 21.4344 7.35588 21.4981 7.41959 21.5475C8.04254 22.0281 8.85838 22.0895 9.55493 21.7183L24.2967 13.7252C24.9292 13.3897 25.3216 12.7285 25.3309 12.0118Z"
                fill="#468CBC"
              />
            </svg>
          </div>
        </Box>
        {(
           userHasActionPermissions && request.isEditable
        ) ? (
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
              marginLeft: '-5px',
              backgroundColor: 'white',
            }}
          >
            <Button
              variant="text"
              type="submit"
              onClick={() => handleReject()}
              sx={{
                backgroundColor: '#FBEDEB',
                padding: '10px 24px 10px 24px',
                gap: '8px',
                border: 'none',
                borderRadius: '12px',
                opacity: '0px',
                boxShadow: 'none',
                fontFamily: 'Visby CF Bold, sans-serif',
                textTransform: 'none',
                width: '230px',
                marginLeft: '0px',
                ':hover': {
                  backgroundColor: '#FBEDEB',
                  opacity: 0.9,
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontFamily: 'Visby CF Bold, sans-serif',
                  color: 'black',
                }}
              >
                İmtina et
              </Typography>
            </Button>

            <Button
              variant="text"
              type="submit"
              onClick={() => handleApprove()}
              sx={{
                backgroundColor: '#4BA458',
                padding: '10px 24px 10px 24px',
                gap: '8px',
                border: 'none',
                borderRadius: '12px',
                opacity: '0px',
                boxShadow: 'none',
                fontFamily: 'Visby CF Bold, sans-serif',
                textTransform: 'none',
                width: '230px',
                ':hover': {
                  backgroundColor: '#4BA458',
                  opacity: 0.9,
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontFamily: 'Visby CF Bold, sans-serif',
                  color: 'white',
                  ':hover': { textDecoration: 'none' },
                }}
              >
                Təsdiq et
              </Typography>
            </Button>
          </Box>
        ) : null}
      </Footer>
    </div>
  );
}

function getNotification({
  reason,
  reasonNote,
  subtituteTeacher,
  isApprove,
  isStudentPermission,
  isOnline,
  isReject
}: any) {
  if (isStudentPermission && isOnline) {
    return (
      <Box
        sx={{
          display: 'flex',
          marginTop: '24px',
          padding: '12px 24px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '2px',
          alignSelf: ' stretch',
          borderRadius: '16px',
          backgroundColor: '#F7F7F7',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clip-path="url(#clip0_13842_1190)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM9 11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55229 10 9 10.4477 9 11ZM6.92082 6.08541C7.00133 5.92438 7.11032 5.78617 7.25977 5.68654C7.40453 5.59004 7.63124 5.5 8 5.5C8.27975 5.5 8.55291 5.58656 8.7375 5.725C8.90206 5.84842 9 6.01009 9 6.25C9 6.42662 8.95919 6.51381 8.92283 6.56835C8.87748 6.63639 8.79924 6.71162 8.64646 6.81349C8.57007 6.86441 8.48788 6.9139 8.38754 6.97411L8.38141 6.97779L8.38139 6.9778C8.28769 7.03402 8.17704 7.10041 8.06831 7.1729C7.84608 7.32107 7.58057 7.52712 7.37593 7.83412C7.14619 8.17878 7.23935 8.64442 7.58401 8.87417C7.92867 9.10391 8.39432 9.01075 8.62406 8.66609C8.66942 8.59803 8.74766 8.52279 8.90043 8.42093C8.97681 8.37001 9.059 8.32052 9.15933 8.26032L9.16554 8.25659L9.1656 8.25656C9.25926 8.20036 9.36986 8.134 9.47854 8.06154C9.70075 7.91339 9.96627 7.70737 10.1709 7.4004C10.3846 7.07994 10.5 6.69838 10.5 6.25C10.5 5.48991 10.1396 4.90158 9.6375 4.525C9.15542 4.16344 8.55358 4 8 4C7.36876 4 6.84547 4.15996 6.42772 4.43846C6.01467 4.71383 5.74866 5.07562 5.57918 5.41459C5.39393 5.78507 5.5441 6.23558 5.91459 6.42082C6.28507 6.60606 6.73558 6.45589 6.92082 6.08541Z"
                fill="#468CBC"
              />
            </g>
            <defs>
              <clipPath id="clip0_13842_1190">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        <Typography
          sx={{
            fontSize: '16px',
            fontFamily: 'Visby CF Bold',
            color: '#4F5259',
            marginLeft: '23px',
          }}
        >
          Onlayn iştirak edə bilirəm.
        </Typography>
        </Box>
      </Box>
    );
  }
  if (isApprove) {
     if (subtituteTeacher) {
      return (
        <Box
          sx={{
            marginTop: '24px',
            display: 'flex',
            padding: '12px 24px',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: ' stretch',
            borderRadius: '16px',
            backgroundColor: '#F7F7F7',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_13842_1190)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM9 11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55229 10 9 10.4477 9 11ZM6.92082 6.08541C7.00133 5.92438 7.11032 5.78617 7.25977 5.68654C7.40453 5.59004 7.63124 5.5 8 5.5C8.27975 5.5 8.55291 5.58656 8.7375 5.725C8.90206 5.84842 9 6.01009 9 6.25C9 6.42662 8.95919 6.51381 8.92283 6.56835C8.87748 6.63639 8.79924 6.71162 8.64646 6.81349C8.57007 6.86441 8.48788 6.9139 8.38754 6.97411L8.38141 6.97779L8.38139 6.9778C8.28769 7.03402 8.17704 7.10041 8.06831 7.1729C7.84608 7.32107 7.58057 7.52712 7.37593 7.83412C7.14619 8.17878 7.23935 8.64442 7.58401 8.87417C7.92867 9.10391 8.39432 9.01075 8.62406 8.66609C8.66942 8.59803 8.74766 8.52279 8.90043 8.42093C8.97681 8.37001 9.059 8.32052 9.15933 8.26032L9.16554 8.25659L9.1656 8.25656C9.25926 8.20036 9.36986 8.134 9.47854 8.06154C9.70075 7.91339 9.96627 7.70737 10.1709 7.4004C10.3846 7.07994 10.5 6.69838 10.5 6.25C10.5 5.48991 10.1396 4.90158 9.6375 4.525C9.15542 4.16344 8.55358 4 8 4C7.36876 4 6.84547 4.15996 6.42772 4.43846C6.01467 4.71383 5.74866 5.07562 5.57918 5.41459C5.39393 5.78507 5.5441 6.23558 5.91459 6.42082C6.28507 6.60606 6.73558 6.45589 6.92082 6.08541Z"
                  fill="#468CBC"
                />
              </g>
              <defs>
                <clipPath id="clip0_13842_1190">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <Typography
              sx={{
                fontSize: '16px',
                color: '#4E4C4D',
                fontFamily: 'Visby CF Regular',
              }}
            >
              Dərsin statusu:
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                color: '#4E4C4D',
                fontFamily: 'Visby CF Bold',
              }}
            ></Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: ' flex-end',
              gap: '2px',
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                color: '#999898',
                fontFamily: 'Visby CF Regular',
              }}
            >
              Əvəzedici müəllim:
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                color: '#4E4C4D',
                fontFamily: 'Visby CF Bold',
              }}
            >
              {`${subtituteTeacher?.name} ${subtituteTeacher?.surname}`}
            </Typography>
          </Box>
        </Box>
      );
    } else {

      return (
        <Box
          sx={{
            display: 'flex',
            marginTop: '24px',
            padding: '12px 24px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '2px',
            alignSelf: ' stretch',
            borderRadius: '16px',
            backgroundColor: '#F7F7F7',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_13842_1190)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM9 11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55229 10 9 10.4477 9 11ZM6.92082 6.08541C7.00133 5.92438 7.11032 5.78617 7.25977 5.68654C7.40453 5.59004 7.63124 5.5 8 5.5C8.27975 5.5 8.55291 5.58656 8.7375 5.725C8.90206 5.84842 9 6.01009 9 6.25C9 6.42662 8.95919 6.51381 8.92283 6.56835C8.87748 6.63639 8.79924 6.71162 8.64646 6.81349C8.57007 6.86441 8.48788 6.9139 8.38754 6.97411L8.38141 6.97779L8.38139 6.9778C8.28769 7.03402 8.17704 7.10041 8.06831 7.1729C7.84608 7.32107 7.58057 7.52712 7.37593 7.83412C7.14619 8.17878 7.23935 8.64442 7.58401 8.87417C7.92867 9.10391 8.39432 9.01075 8.62406 8.66609C8.66942 8.59803 8.74766 8.52279 8.90043 8.42093C8.97681 8.37001 9.059 8.32052 9.15933 8.26032L9.16554 8.25659L9.1656 8.25656C9.25926 8.20036 9.36986 8.134 9.47854 8.06154C9.70075 7.91339 9.96627 7.70737 10.1709 7.4004C10.3846 7.07994 10.5 6.69838 10.5 6.25C10.5 5.48991 10.1396 4.90158 9.6375 4.525C9.15542 4.16344 8.55358 4 8 4C7.36876 4 6.84547 4.15996 6.42772 4.43846C6.01467 4.71383 5.74866 5.07562 5.57918 5.41459C5.39393 5.78507 5.5441 6.23558 5.91459 6.42082C6.28507 6.60606 6.73558 6.45589 6.92082 6.08541Z"
                  fill="#468CBC"
                />
              </g>
              <defs>
                <clipPath id="clip0_13842_1190">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <Typography
              sx={{
                fontSize: '16px',
                color: '#4E4C4D',
                fontFamily: 'Visby CF Regular',
              }}
            >
              Dərsin statusu:
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: '16px',
              fontFamily: 'Visby CF Bold',
              color: '#4F5259',
              marginLeft: '23px',
            }}
          >
            {reason?.name}
          </Typography>
        </Box>
      );
    }
  } else if(isReject) {
    return (
      <Box
        sx={{
          display: 'flex',
          marginTop: '24px',
          padding: '12px 24px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '2px',
          alignSelf: ' stretch',
          borderRadius: '16px',
          backgroundColor: '#F7F7F7',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clip-path="url(#clip0_13842_1190)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM9 11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55229 10 9 10.4477 9 11ZM6.92082 6.08541C7.00133 5.92438 7.11032 5.78617 7.25977 5.68654C7.40453 5.59004 7.63124 5.5 8 5.5C8.27975 5.5 8.55291 5.58656 8.7375 5.725C8.90206 5.84842 9 6.01009 9 6.25C9 6.42662 8.95919 6.51381 8.92283 6.56835C8.87748 6.63639 8.79924 6.71162 8.64646 6.81349C8.57007 6.86441 8.48788 6.9139 8.38754 6.97411L8.38141 6.97779L8.38139 6.9778C8.28769 7.03402 8.17704 7.10041 8.06831 7.1729C7.84608 7.32107 7.58057 7.52712 7.37593 7.83412C7.14619 8.17878 7.23935 8.64442 7.58401 8.87417C7.92867 9.10391 8.39432 9.01075 8.62406 8.66609C8.66942 8.59803 8.74766 8.52279 8.90043 8.42093C8.97681 8.37001 9.059 8.32052 9.15933 8.26032L9.16554 8.25659L9.1656 8.25656C9.25926 8.20036 9.36986 8.134 9.47854 8.06154C9.70075 7.91339 9.96627 7.70737 10.1709 7.4004C10.3846 7.07994 10.5 6.69838 10.5 6.25C10.5 5.48991 10.1396 4.90158 9.6375 4.525C9.15542 4.16344 8.55358 4 8 4C7.36876 4 6.84547 4.15996 6.42772 4.43846C6.01467 4.71383 5.74866 5.07562 5.57918 5.41459C5.39393 5.78507 5.5441 6.23558 5.91459 6.42082C6.28507 6.60606 6.73558 6.45589 6.92082 6.08541Z"
                fill="#468CBC"
              />
            </g>
            <defs>
              <clipPath id="clip0_13842_1190">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <Typography
            sx={{
              fontSize: '16px',
              color: '#4E4C4D',
              fontFamily: 'Visby CF Regular',
            }}
          >
            İmtina səbəbi:
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: '16px',
            fontFamily: 'Visby CF Bold',
            color: '#4F5259',
            marginLeft: '23px',
          }}
        >
          {reason.name === 'Digər' ? reasonNote : reason?.name}
        </Typography>
      </Box>
    );
  }
}
