import {
    Select,
    MenuItem
  } from "@mui/material";
import React, { useContext, useState } from 'react';
import { AttendanceStudentsContext } from '../../../../../../context/AttendanceStudentsContext';
import { AttendanceContext } from "../../../../../../context/AttendanceContext";

export default function PresenceModal({setOpenModal}: any){
    const {handleSelectedStudentsAttendanceHours} = useContext(AttendanceStudentsContext);
    const {totalHours, currentNum, setCurrentNum} = useContext(AttendanceContext);

    const handleCheck = (val: number) => {
        setCurrentNum(val);
        handleSelectedStudentsAttendanceHours(val);
        setOpenModal(false);
    }

    return (
      <>
        {
            totalHours?.map((elem: any)=> (
                <MenuItem selected={elem === currentNum} key={elem} value={elem} onClick={()=>handleCheck(elem)}>{elem}</MenuItem>
            ))
        }
      </>
    )
  }
