import { Box } from '@mui/system';
import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { useGetActionReasons, usePostAction } from '../../../../../../../../../api/requests';
import Footer from '../footer';
import Button from '../../../../../../../../../components/core-components/inputs/button';
import { useQueryClient } from 'react-query';
import { apiRoutes } from '../../../../../../../../../apiRoutes';

export default function Index({createdDate, requestId, setStage}: any) {
  const postActionMutation = usePostAction(requestId);
  const queryClient = useQueryClient();
  const [selectedRejectValue, setSelectedRejectValue] = useState<string>('');
  const [textValue, setTextValue] = useState<string>('');
  const {
    data: reasons,
    error,
    refetch: actionsRefetch,
  } = useGetActionReasons('reject');

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedRejectValue(value);
  };

  const handleReject = async () => {
    try{
      await postActionMutation.mutateAsync({
        reasonId: selectedRejectValue,
        reasonNote: textValue,
        substituteTeacherId: 0,
        status: "Reject"
      })
      queryClient.invalidateQueries(apiRoutes.leaverequests.myRequests);
      queryClient.invalidateQueries(apiRoutes.leaverequests.getAllRequests);

      queryClient.invalidateQueries(apiRoutes.leaverequests.getRequest(requestId));
      setStage(0)
    } catch(err){

    }
  }

  const isDisabled = () : boolean => {
    if(selectedRejectValue){
      if(reasons?.find((x: any) => x.id == selectedRejectValue)?.reasonAction ==='Note' && !textValue){
        return true;
      }
      return false;
    }
    return true;
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          padding: '12px 24px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '4px',
          alignSelf: 'stretch',
          borderRadius: '16px',
          backgroundColor: '#F7F7F7',
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontFamily: 'Visby CF Bold',
            color: '#4E4C4D',
          }}
        >
          İmtina səbəbi:
        </Typography>
        <RadioGroup value={selectedRejectValue} onChange={handleRadioChange}>
          { Array.isArray(reasons) ? reasons?.map((reason: { id: number; name: string }) => (
            <FormControlLabel
              key={reason.id}
              value={reason.id.toString()}
              control={<Radio />}
              label={reason.name}
            />
          )) : null}
        </RadioGroup>
      </Box>

      {reasons?.find((x: any) => x.id == selectedRejectValue)?.reasonAction ===
      'Note' ? (
        <TextField
          placeholder="Digər imtina səbəbini daxil edin..."
          variant="outlined"
          value={textValue}
          onChange={(e) => setTextValue(e.target.value)}
          sx={{
            backgroundColor: '#F7F7F7',
            width: '100%',
            marginTop: '8px',
            display: 'flex',
            border: '0px',
            height: '113px',
            borderRadius: '16px',
            padding: '16px 24px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
            },
          }}
        />
      ) : null}
      <Footer createdDate={createdDate}>
        <Button
          variant="text"
          type="submit"
          onClick={()=> handleReject()}
          disabled={isDisabled()}
          sx={{
            backgroundColor: '#D54939',
            padding: '10px 24px 10px 24px',
            // gap: '8px',
            border: 'none',
            borderRadius: '12px',
            opacity: '0px',
            boxShadow: 'none',
            fontFamily: 'Visby CF Bold, sans-serif',
            textTransform: 'none',
            width: '100%',
            ':disabled': {
              backgroundColor: '#FBEDEB',
            },
            ':hover': {
              backgroundColor: '#D54939',
              opacity: 0.9,
            },
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontFamily: 'Visby CF Bold, sans-serif',
              color: 'white',
            }}
          >
            İmtina et
          </Typography>
        </Button>
      </Footer>
    </Box>
  );
}
