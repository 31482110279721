import React from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface StyledDropzoneProps {
  onFilesChange: (files: File[]) => void;
}

function StyledDropzone({ onFilesChange }: StyledDropzoneProps) {
  const [files, setFiles] = React.useState<File[]>([]); // Yüklenen dosyalar için durum
 

  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      if (files.length + acceptedFiles.length > 2) {
        alert("Maksimum 2 dosya yükleyebilirsiniz.");
        return;
      }
      const newFiles = [...files, ...acceptedFiles];
      setFiles(newFiles);
      onFilesChange(newFiles); // Dosya değişikliklerini üst bileşene ilet
    },
    [files, onFilesChange]
  );

  const removeFile = (index: number) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    onFilesChange(updatedFiles); // Dosya kaldırıldığında da üst bileşeni bilgilendir
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: files.length >= 2, // Maksimum 2 dosya olduğunda devre dışı bırak
  });

  return (
    <div>
      <div
        {...getRootProps()}
        style={{
          border: '1px dashed #AACAE0',
          textAlign: 'center',
          color: 'gray',
          marginBottom: '20px',
          cursor: files.length < 2 ? 'pointer' : 'not-allowed',
          backgroundColor: isDragActive ? '#f0f0f0' : '#FAFDFF',
          width: '626px',
          height: '170px',
          padding: '24px',
          gap: '12px',
          borderRadius: '16px',
        }}
      >
        <CloudUploadIcon sx={{ color: '#468CBC' }} />
        <input {...getInputProps()} />
        {files.length === 0 ? (
           <Box sx={{ marginTop: '-10px',display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',gap: '8px' }}>
           <p style={{ color: '#000000' }}>
             <span
               style={{
                 color: '#468CBC',
                 fontFamily: 'Visby CF Bold',
                 textDecoration: 'underline',
               }}
             >
               Faylı yükləyin
             </span>{' '}
             ya da buraya çəkib atın
             <Box sx={{ marginTop: '-12px' }}>
               <h4>PDF, Word, JPG or PNG</h4>
             </Box>
             <Box sx={{ marginTop: '-12px' }}>
               <h4>Max. 3MB</h4>
             </Box>
           </p>
         </Box>
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            
            <Box sx={{ marginTop: '-10px' }}>
           <p style={{ color: '#000000' }}>
             <span
               style={{
                 color: '#468CBC',
                 fontFamily: 'Visby CF Bold',
                 textDecoration: 'underline',
               }}
             >
               Faylı yükləyin
             </span>{' '}
             ya da buraya çəkib atın
           </p>
         </Box>

            {files.map((file, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  marginTop:"-12px",
                  marginLeft:`${240 - Math.min(file.name.length, 20)}px`,
                  alignItems: 'center',
                  width: '80%', // Dosya adlarını daha dar bir genişlikte ortalar
                  maxWidth: '400px', // Maksimum genişlik belirler
                  // İsteğe bağlı, dosya adlarının etrafına çerçeve ekler
                  
                }}
              >
                <Typography variant="body2" sx={{ color: '#6B696A', fontSize:"14px" }}>
                {file.name.length > 20 ? `${file.name.slice(0, 20)}...` : file.name}
                </Typography>
                <IconButton
                  onClick={() => removeFile(index)}
                  sx={{ color: '#FF5A5A' }}
                >
                  <DeleteOutlineIcon sx={{color:"#999898"}}/>
                </IconButton>
              </Box>
            ))}
          </Box>
        )}
      </div>
    </div>
  );
}

export default StyledDropzone;
