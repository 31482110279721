import {
    Checkbox,
    TableCell,
    TableHead,
    TableRow,
  } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AttendanceContext } from "../../../../../../../context/AttendanceContext";
import { AttendanceStudentsContext } from "../../../../../../../context/AttendanceStudentsContext";


const sxTableCell: React.CSSProperties = {
    fontSize: "14px",
    fontFamily: 'Visby CF Bold, sans-serif',
    color: "#000000",
    padding:"12px 16px",
}

export default function Header({type}: any) {

  const {handleAllStudentsCheck, areAllChecked} = useContext(AttendanceStudentsContext);
  const { totalHours: selectTotalHours } = useContext(AttendanceContext);

  const [isChecked, setIsChecked] = useState(false);

  useEffect(()=> {
    setIsChecked(areAllChecked);
  }, [areAllChecked])

  return (
    <TableHead>
        <TableRow>
            <TableCell sx={[sxTableCell, { width: '2%'}]} align="left">
              <Checkbox checked={isChecked} onChange={(e)=> {
                handleAllStudentsCheck(!isChecked, selectTotalHours[selectTotalHours.length -1])
                setIsChecked(!isChecked)
              }}/>
            </TableCell>
            <TableCell sx={[sxTableCell, { fontWeight: "700", width: '2%' }]} align="left">
              №
            </TableCell>
            <TableCell sx={[sxTableCell]} align="left">
                Tələbə ad və Soyad
            </TableCell>
            <TableCell sx={[sxTableCell, { width: '6%'}]} align="right">
                {type}
            </TableCell>
            <TableCell sx={[sxTableCell, { width: '4%'}]} align="left">
            </TableCell>
            <TableCell sx={[sxTableCell, { width: '6%'}]} align="right">
                D.F.
            </TableCell>
        </TableRow>
    </TableHead>
  )
}
