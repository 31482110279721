import { useContext, useEffect, useState } from "react";
import { AttendanceContext } from "../../../../../../context/AttendanceContext";
import { Kpi } from "../../../../EducationSystemManagement/groups/components/kpi";

export default function LessonDuration() {

  const {totalHours, startTime, isActive} = useContext(AttendanceContext);
  const [elapsedSeconds, setElapsedSeconds] = useState(0); // Elapsed time in seconds
  const [isRunning, setIsRunning] = useState(true); // Stop when timer reaches endTime

  // Helper function to parse "hh:mm:ss" string into seconds
  const parseTimeToSeconds = (time: any) => {
    const [hours, minutes, seconds] = time?.split(":").map(Number);
    return hours * 3600 + minutes * 60 + (seconds || 0); // Account for seconds
  };

  // Helper function to format seconds into hh:mm:ss
  const formatTime = (seconds: any) => {
    const hrs = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const mins = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const secs = String(seconds % 60).padStart(2, "0");
    return `${hrs}:${mins}`;
  };

  useEffect(() => {
    if(startTime){
      const startSeconds = parseTimeToSeconds(startTime);
      const endSeconds = startSeconds + totalHours[totalHours.length-1] * 3600; // Calculate endTime in seconds
      const now = new Date();
      const currentSeconds =
        now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds(); // Current time in seconds
  
      // Calculate initial elapsed time
      const initialElapsed = currentSeconds - startSeconds;
  
      // If current time is past the calculated end time, stop immediately
      if (currentSeconds >= endSeconds) {
        setElapsedSeconds(endSeconds - startSeconds); // Set total elapsed time
        setIsRunning(false); // Stop the timer
        return;
      }
  
      setElapsedSeconds(initialElapsed > 0 ? initialElapsed : 0); // Start from 0 if negative
  
      // Increment elapsed time every second
      const interval = setInterval(() => {
        setElapsedSeconds((prevElapsed) => {
          // Stop the timer when it reaches endTime
          if (prevElapsed >= endSeconds - startSeconds) {
            clearInterval(interval);
            setIsRunning(false);
            return prevElapsed;
          }
          return prevElapsed + 1; // Increment elapsed seconds
        });
      }, 1000);
  
      return () => clearInterval(interval); // Cleanup interval on component unmount
      
    }
  }, [startTime, totalHours]);
  

  return (
    <>
      <Kpi
        value={
          startTime && isActive ? (<div>{formatTime(elapsedSeconds)}</div>) : (<div>00:00</div>)
        }
        color={startTime && isActive ? "#4BA458" : '#6B696A'}
        tagTitle="Dərs müddəti"
        iconColor={startTime && isActive ? "#C7E3CB" : '#6B696A'}
      />
    </>
  )
}