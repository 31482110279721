export const apiRoutes = {
  attendances: {
    base: 'api/Attendances',
    getAll: 'Attendances',
    workers(id: number) {
      return `${this.base}/classes/${id}/workers`;
    },
    get start() {
      return `${this.base}/start`;
    },
    get end() {
      return `${this.base}/end`;
    },
    get join() {
      return `${this.base}/join`;
    },
  },
  auth: {
    base: 'Auth',
    get logout() {
      return `${this.base}/logout`;
    },
    get login() {
      return `${this.base}/login`;
    },
    get register() {
      return `${this.base}/register`;
    },
    get confirm() {
      return `${this.base}/confirm`;
    },
    get refreshPassword() {
      return `${this.base}/refresh-password`;
    },
    get updatePassword() {
      return `${this.base}/update-password`;
    },
  },
  classes: {
    base: 'api/Classes',
    get getAll() {
      return this.base;
    },
    get create() {
      return this.base;
    },
    getById(id: number) {
      return `${this.base}/${id}`;
    },
    update(id: number) {
      return `${this.base}/${id}`;
    },
    delete(id: number) {
      return `${this.base}/${id}`;
    },
    cancelSession(id: number) {
      return `${this.base}/${id}/session-cancel`;
    },
    get count() {
      return `${this.base}/count`;
    },
    sessions(id: number, category: string) {
      return `${this.base}/${id}/attendance?category=${category}`;
    },
    addSessions(id: number) {
      return `${this.base}/${id}/sessions`;
    },
    updateSessions(id: number) {
      return `${this.base}/${id}/sessions`;
    },
    modules(id: number) {
      return `${this.base}/${id}/modules`;
    },
    heldModules(id: number) {
      return `${this.base}/${id}/held-modules`;
    },
    heldModulesAdmin(id: number) {
      return `${this.base}/${id}/held-modules/admin`;
    },
    modulesWorkers(id: number) {
      return `${this.base}/${id}/modules-workers`;
    },
    updateModulesWorkers(id: number) {
      return `${this.base}/${id}/modules-workers`;
    },
    createModulesWorkers(id: number) {
      return `${this.base}/${id}/modules-workers`;
    },
    students(id: number) {
      return `${this.base}/${id}/students`;
    },
    addStudents(id: number) {
      return `${this.base}/${id}/students`;
    },
    workers(id: number) {
      return `${this.base}/${id}/workers`;
    },
    startLesson(id: number) {
      return `${this.base}/${id}/attendance/start`;
    },
    totalHours(id:number){
      return `${this.base}/${id}/generate-assign-workers`;
    }
  },
  classTimeSheets: {
    base: 'api/ClassTimeSheets',
    attendance(id: number) {
      return `${this.base}/${id}/attendance`;
    },
    attendanceEnd(id: number) {
      return `${this.base}/${id}/attendance/end`;
    },
    join(id: number) {
      return `${this.base}/${id}/join`;
    },
  },
  supports: {
    base: 'api/Supports',
    get getAll() {
      return this.base;
    },
    get create() {
      return this.base;
    },
    update(id: number) {
      return `${this.base}/${id}`;
    },
    delete(id: number) {
      return `${this.base}/${id}`;
    },
    getById(id: number) {
      return `${this.base}/${id}`;
    },
    get getCategories() {
      return `${this.base}/categories`;
    },
  },
  programs: {
    base: 'api/Programs',
    modules(id: number) {
      return `${this.base}/${id}/modules`;
    },
  },
  users: {
    base: 'api/Users',
    get getAll() {
      return this.base;
    },
    get delete() {
      return `${this.base}`;
    },
  },
  permissions: {
    base: 'api/PermissionGroups',
    get getAll() {
      return this.base;
    },
    getById(id: number) {
      return `${this.base}/${id}/with-users`;
    },
    get create() {
      return this.base;
    },
    get delete() {
      return this.base;
    },
    addWorker(id: number) {
      return `${this.base}/${id}/add-worker`;
    },
  },
  projects: {
    base: 'api/Projects',
    get getAll() {
      return this.base;
    },
    programs(id: number) {
      return `${this.base}/${id}/programs`;
    },
  },
  sessions: {
    base: 'api/Sessions',
    get getAll() {
      return this.base;
    },
  },
  rooms: {
    base: 'api/Rooms',
    get getAll() {
      return this.base;
    },
    planning(sessions: number[], rooms: number[]) {
      const roomArray = rooms.map((room) => `rooms=${room}`);
      const roomQuery = roomArray.join('&');
      const sessionArray = sessions.map((room) => `sessions=${room}`);
      const sessionQuery = sessionArray.join('&');
      return `${this.base}/planning?${sessionQuery}&${roomQuery}`;
    },
  },
  holidays: {
    base: 'api/Holidays',

    get getAll() {
      return this.base;
    },
    get delete() {
      return this.base;
    },
    get create() {
      return this.base;
    },
    update(id: number) {
      return this.base;
    },
  },

  workers: {
    base: 'api/Workers',

    get getAll() {
      return this.base;
    },

    get delete() {
      return this.base;
    },

    get addWorker() {
      return this.base;
    },
    get loginClasses() {
      return `${this.base}/login/classes`;
    },
    get filteredDatas() {
      return `${this.base}/filteredDatas`;
    },
    get allExamModules() {
      return `${this.base}/GetAllExamModule`;
    },
    get createExamSheet() {
      return `${this.base}/CreateExamSheet`;
    },
    get createSurveySheet() {
      return `${this.base}/CreateSurveySheet`;
    },
  },

  roles: {
    base: 'api/Roles',

    get getAll() {
      return this.base;
    },

    get assignRolesToWorker() {
      return `${this.base}/set`;
    },
  },

  students: {
    base: 'api/Students',

    get getAll() {
      return this.base;
    },

    getStudentAttendance(studentId: number, classId: number) {
      return `${this.base}/${studentId}/attendances?classId=${classId}`;
    },

    getStudentDetails(studentId: number, classId: number) {
      return `/api/Classes/${classId}/students-details`;
    },
  },

  leaverequests: {
    base: 'api/LeaveRequests',

    get getAll() {
      return `${this.base}/types`;
    },
    get addRequest() {
      return this.base;
    },

    get getAllRequests() {
      return `${this.base}`;
    },

    get myRequests() {
      return `${this.base}/my`;
    },
    open(id: number){
      return `${this.base}/${id}/open`;
    },
    getComments(id: number) {
      return `${this.base}/${id}/comments`;
    },

    addComment(id: number) {
      return `${this.base}/${id}/comments`;
    },

    getRequest(id: number) {
      return `${this.base}/${id}`;
    },
    postAction(id: number) {
      return `${this.base}/${id}/actions`;
    },
    postFile(id: number) {
      return `${this.base}/${id}/files`;
    },
    getActionHistories(id: number) {
      return `${this.base}/${id}/histories`;
    },
    getActionSteps(id: number){
      return `${this.base}/${id}/actions-steps`;
    },
    get getAllRequestes() {
      return `${this.base}`;
    },

    get actionReasons() {
      return `${this.base}/action-reasons`;
    },

    Teachers(id: number) {
      return `${this.base}/${id}/substitute-teachers`;
    },
  },
};
