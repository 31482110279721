import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import Footer from '../footer';
import Button from '../../../../../../../../../components/core-components/inputs/button';
import { usePostAction } from '../../../../../../../../../api/requests';
import { useQueryClient } from 'react-query';
import { apiRoutes } from '../../../../../../../../../apiRoutes';

export default function Index({
  studentName,
  createdDate,
  requestId,
  isApprove,
  setStage
}: any) {
  const postActionMutation = usePostAction(requestId);
  const queryClient = useQueryClient();

  const handleApprove = async () => {
    try {
      const res = await postActionMutation.mutateAsync({
        reasonId: null,
        reasonNote: '',
        substituteTeacherId: null,
        status: isApprove ? 'Approve' : 'Reject',
      });
      queryClient.invalidateQueries(apiRoutes.leaverequests.myRequests);
      queryClient.invalidateQueries(apiRoutes.leaverequests.getAllRequests);
      queryClient.invalidateQueries(apiRoutes.leaverequests.getRequest(requestId));
      setStage(0);
    } catch (err) {}
  };

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      {isApprove ? (
        <>
          <Typography sx={{ fontSize: '16px' }}>
            <span style={{ fontFamily: 'Visby CF Bold' }}>{studentName}</span>{' '}
            adlı tələbəyə sənəd təqdim etdiyi halda icazə verilə bilər. Onlayn
            iştirak etdiyi təqdirdə davamiyyətdə <span style={{ fontFamily: 'Visby CF Bold' }}>“Dərsdə iştirak etmiş” kimi əks</span>
            olunacaq.
            <span style={{ fontFamily: 'Visby CF Bold' }}>
              {' '}
              İcazəni təsdiq etmək istədiyinizdən əminsiniz?
            </span>
          </Typography>
          <Footer createdDate={createdDate}>
            <Button
              variant="text"
              type="submit"
              onClick={() => handleApprove()}
              sx={{
                backgroundColor: '#4BA458',
                padding: '10px 24px 10px 24px',
                gap: '8px',
                border: 'none',
                borderRadius: '12px',
                opacity: '0px',
                boxShadow: 'none',
                fontFamily: 'Visby CF Bold, sans-serif',
                textTransform: 'none',
                width: '100%',
                ':disabled': {
                  backgroundColor: '#4BA458',
                  opacity: 0.9,
                },
                ':hover': {
                  backgroundColor: '#4BA458',
                  opacity: 0.9,
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontFamily: 'Visby CF Bold, sans-serif',
                  color: 'white',
                }}
              >
                Təsdiq et
              </Typography>
            </Button>
          </Footer>
        </>
      ) : (
        <>
          <Typography sx={{ fontSize: '16px' }}>
            İcazədən imtina etdiyiniz halda 
            <span style={{ fontFamily: 'Visby CF Bold' }}>{studentName}</span>{' '}
            adlı tələbənin
            davamiyyətində düzəliş edilməyəcək və o gün <span style={{ fontFamily: 'Visby CF Bold' }}>“Dərsdə iştirak etməmiş”</span>
            kimi qeydə alınacaq.
            <span style={{ fontFamily: 'Visby CF Bold' }}>
              {' '}
              İcazədən imtina etmək istədiyinizdən əminsiniz?
            </span>
          </Typography>
          <Footer createdDate={createdDate}>
            <Button
              variant="text"
              type="submit"
              onClick={() => handleApprove()}
              sx={{
                backgroundColor: '#D54939',
                padding: '10px 24px 10px 24px',
                // gap: '8px',
                border: 'none',
                borderRadius: '12px',
                opacity: '0px',
                boxShadow: 'none',
                fontFamily: 'Visby CF Bold, sans-serif',
                textTransform: 'none',
                width: '100%',
                ':disabled': {
                  backgroundColor: '#FBEDEB',
                },
                ':hover': {
                  backgroundColor: '#D54939',
                  opacity: 0.9,
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontFamily: 'Visby CF Bold, sans-serif',
                  color: 'white',
                }}
              >
                İmtina et
              </Typography>
            </Button>
          </Footer>
        </>
      )}
    </Box>
  );
}
