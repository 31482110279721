import { useEffect, useState } from 'react';
import AttendanceCard, {
  IAttendanceCardModel,
} from './components/attendance-card';
import UnmarkedAttendanceChip, {IUnmarkedAttendanceChip} from './components/unmarkedAttendanceChip';
import FormControl from '@mui/material/FormControl';
import { IconButton, CircularProgress } from '@mui/material';
import { Months } from '../../../../../types';
import { BaseService } from '../../../../../network/services/base/BaseService';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import azLocale from "dayjs/locale/az";
import dayjs from "dayjs";
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';

interface IAttendanceModel {
  classId: string;
}

let capitalizedMonthNames: any[] = [];

if (azLocale.months && Array.isArray(azLocale.months)) {
  capitalizedMonthNames = azLocale.months.map((monthName) => {
    return monthName.charAt(0).toUpperCase() + monthName.slice(1);
  });
}

const customAzLocale = {
  ...azLocale,
  months: capitalizedMonthNames,
};

export default function Attendance({ classId }: IAttendanceModel) {
  const [days, setDays] = useState<IAttendanceCardModel[]>([]);
  const [unMarkedAttendance, setUnMarkedAttendance] = useState<IUnmarkedAttendanceChip[]>([]);
  const [date, setDate] = useState<any>(dayjs(new Date()));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const classService = new BaseService(`api/Classes`);
        const month = Months[Object.keys(Months)[date.$M] as (keyof typeof Months)]
        const res = await classService.getAll(
          `api/Classes/${classId}/attendance-rate?month=${month}&year=${date.$y}`
        );
        if (res.statusCode === 200) {
          setDays(res.data as IAttendanceCardModel[]);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
    getData();
  }, [date, classId]);

  useEffect(()=> {
    async function getData() {
        setLoading(true);
        try {
          const classService = new BaseService(`api/Classes`);
          const res = await classService.getAll(
            `api/Classes/${classId}/unmarked-attendance-days`
          );
          if (res.statusCode === 200) {
            setUnMarkedAttendance(res.data as IUnmarkedAttendanceChip[]);
          }
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      }
      getData();
  }, [classId]);

  useEffect(() => {
    dayjs.locale(customAzLocale);
  }, []);


  const handleNextMonth = () => {
    var now = new Date(date);
    var current;
    if (now.getMonth() === 11) {
      current = new Date(now.getFullYear() + 1, 0, 1);
    } else {
      current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    } 
    setDate(dayjs(current))
  }

  const handlePrevMonth = () => {
    var now = new Date(date);
    now.setDate(0);
    setDate(dayjs(now))
  }

  return (
    <div className='hideScrollBar' style={{ paddingTop: '24px', width: '100%', overflowY: 'scroll' }}>
        <div className='hideScrollBar' style={{ display: 'flex', marginBottom: '20px', width: '1280px', overflowX: 'scroll'}}>
          {
            unMarkedAttendance?.map((elem, index)=> (
              <UnmarkedAttendanceChip key={index} url="" date={elem.date} classId={classId}/>
            ))
          }
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            <FormControl>
              <LocalizationProvider
                locale={customAzLocale}
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  format="MMMM YYYY"
                  views={['month', 'year']}
                  value={dayjs(date)}
                  disabled={loading}
                  onChange={(innerDate) => {
                    setDate(innerDate);
                  }}
                  sx={{border: 'none', width: '70%'}}
                  slotProps={{ textField: { variant: 'standard', } }}
                  components={{
                    OpenPickerIcon: ArrowDropDownSharpIcon,
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          <div>
            <IconButton sx={{ bgcolor: '#f3f3f3', mr: '16px' }} size="small" onClick={handlePrevMonth}>
              <ArrowBackIosNewSharpIcon fontSize="small" />
            </IconButton>
            <IconButton sx={{ bgcolor: '#f3f3f3' }} size="small" onClick={handleNextMonth}>
              <ArrowForwardIosSharpIcon fontSize="small" />
            </IconButton>
          </div>
        </div>
        {
            loading ? (
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress/>
                </div>
            ) : (
                <>
                {days.length === 0 ? (
                    <p style={{ textAlign: 'center' }}>No data</p>
                  ) : (
                    <>
                      <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                        {days?.map((elem, index) => (
                          <AttendanceCard
                            key={index}
                            classId={classId}
                            totalStudentsCount={elem.totalStudentsCount ?? 0}
                            status={elem.status}
                            date={elem.date}
                            attendingStudentsCount={elem.attendingStudentsCount ?? 0}
                            percentage={
                              elem.attendingStudentsCount != null
                                ? Math.ceil((elem.attendingStudentsCount * 100) /
                                  elem.totalStudentsCount)
                                : 0
                            }
                          />
                        ))}
                      </div>
                    </>
                  )}
                
                </>
            )
        }
    </div>
  );
}
