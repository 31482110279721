import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from '../card/card.module.css';
import {
  calculateDateRange,
  formattoDDMMYY,
} from '../../../../../../../utils/dateFunctions';
import { useGetWorkerLoginClass } from '../../../../../../../api/workers';
import { IWorkerLoginClassModel } from '../../../../../../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store/reducers';
import Tooltip from '@mui/material/Tooltip';
import { UserHasPermission } from '../../../../../../../utils/hooks/userPermissions';
import {
  myLeaveRequestsView,
  allLeaveRequestsView,
  canSeeRole,
  canAcceptRequest,
} from '../../../../../../../permissions';
import { GridColDef } from '@mui/x-data-grid';

const today = new Date();

export const GetAcademicColumns = (
  handleRowClick: (params: any) => void,
  handleGroupClick: (classId: any) => void,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  isVisible: boolean
) => {
  const [hoveredBox, setHoveredBox] = useState<string | null>(null);
  const { data: classss } = useGetWorkerLoginClass();
  const user = useSelector((state: RootState) => (state.auth as any).user);
  const [selectedGroupName, setSelectedGroupName] = useState<string | null>(
    null
  );

  const res: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Sorğunun növü',
      flex: 1.3,
      renderCell: (params: any) => (
        <Box sx={{ padding: '26px 50px 20px 20px' }}>
          <Typography
            sx={{
              fontSize: '16px',
              color: 'black',
              fontFamily: 'Visby CF Bold',
              width: '20px',
            }}
          >
            {params.row.title.toLowerCase()}
          </Typography>
          <Box
            sx={{
              height: 'Hug(24px)',
              padding: '4px 8px 4px 8px',
              background: '#EAF6FF',
              borderRadius: '8px',
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                color: '#2DA9FF',
                fontFamily: 'Visby CF Bold',
              }}
            >
              {params.row.description}
            </Typography>
          </Box>
        </Box>
      ),
      sortable: false,
    },
    {
      field: 'startdate',
      headerName: 'İcazə tarixləri',
      flex: 1.1,
      renderCell: (params: any) => (
        <>
          <Box sx={{ padding: '26px 50px 20px 52px' }}>
            {calculateDateRange(today.toISOString(), params.row?.startdate) <
              7 &&
            calculateDateRange(today.toISOString(), params.row?.startdate) >
              0 ? (
              <Tooltip
                title={
                  <Typography sx={{ fontSize: '14px' }}>
                    İcazənin başlama tarixinə{' '}
                    <span style={{ fontFamily: 'Visby CF Bold' }}>
                      {Math.abs(
                        calculateDateRange(
                          params.row?.startdate,
                          today.toISOString()
                        )
                      )}
                    </span>{' '}
                    gün qalıb.
                  </Typography>
                }
                placement="top"
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    color:
                      calculateDateRange(
                        today.toISOString(),
                        params.row?.startdate
                      ) < 7 &&
                      calculateDateRange(
                        today.toISOString(),
                        params.row?.startdate
                      ) > 0
                        ? '#D54939'
                        : 'black',
                    fontFamily: 'Visby CF Bold',
                  }}
                >
                  {formattoDDMMYY(params.row.startdate)} -{' '}
                  {formattoDDMMYY(params.row.enddate)}
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                sx={{
                  fontSize: '14px',
                  color: 'black',
                  fontFamily: 'Visby CF Bold',
                }}
              >
                {formattoDDMMYY(params.row.startdate)} -{' '}
                {formattoDDMMYY(params.row.enddate)}
              </Typography>
            )}
          </Box>
        </>
      ),
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.9,
      renderCell: (params: any) => {
        const rowStatus = params.row.status;

        return (
          <Box sx={{ padding: '26px 0px 20px 0px' }}>
            <div
              className={`${styles.status} ${
                rowStatus === 'Approved'
                  ? styles.statusapply
                  : rowStatus === 'Pending'
                  ? styles.statuswait
                  : rowStatus === 'Rejected'
                  ? styles.statusreject
                  : styles.statusunknown
              }`}
            >
              {rowStatus === 'Approved'
                ? 'Təsdiqlənib'
                : rowStatus === 'Pending'
                ? 'Gözləmədə'
                : rowStatus === 'Rejected'
                ? 'Qəbul edilməyib'
                : 'Naməlum status'}
            </div>
          </Box>
        );
      },
      sortable: false,
    },
  ];

  if (UserHasPermission(canSeeRole)) {
    res.unshift({
      field: 'role',
      headerName: 'Vəzifəsi',
      flex: 0.8,
      renderCell: (params: any) => (
        <Box sx={{ marginLeft: '50px' }}>
          <Typography sx={{ fontFamily: 'Visby CF Bold', fontSize: '16px' }}>
            {params.value}
          </Typography>
        </Box>
      ),
      sortable: false,
    });
  }

  if (UserHasPermission(allLeaveRequestsView)) {
    res.unshift({
      field: 'requesterUser',
      headerName: 'İcazə alan',
      flex: 1.1,
      // height: "44px",
      renderCell: (params: any) => (
        <Box sx={{ display: 'flex' }}>
          {Math.abs(
            calculateDateRange(params.row?.startdate, today.toISOString()) - 1
          ) > 0 &&
            Math.abs(
              calculateDateRange(params.row?.startdate, today.toISOString()) - 1
            ) < 7 && (
              <Box
                sx={{
                  height: '20px',
                  width: '20px',
                  position: 'absolute',
                  display: 'inline-block',
                }}
              ></Box>
            )}

          <Box sx={{ padding: '26px 50px 20px 50px' }}>
            <Typography
              sx={{
                fontSize: '16px',
                color: 'black',
                fontFamily: 'Visby CF Bold',
              }}
            >
              {params.row?.requesterUser}
            </Typography>
            <Box
              sx={{
                height: 'Hug(24px)',
                padding: '4px 8px 4px 0px',
                width: 'auto',
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  color: 'black',
                  fontFamily: 'Visby CF Regular',
                }}
              >
                {Array.isArray(classss)
                  ? classss?.map((item, index) => (
                      <span
                        key={index}
                        onClick={() => {
                          handleGroupClick(item.id);
                          setSelectedGroupName(item.name);
                        }}
                        style={{
                          fontFamily: 'Visby CF Regular',
                          fontSize: '16px',
                          color: '#0E0D0D',
                        }}
                      >
                        {item.name}
                        {index < classss.length - 1 && (
                          <span style={{ color: '#6B696A' }}>, </span> // Grup ayracı
                        )}
                      </span>
                    ))
                  : null}
              </Typography>
            </Box>
          </Box>
        </Box>
      ),
      sortable: false,
    });
  }

  if (UserHasPermission(canAcceptRequest)) {
    res.push({
      field: 'note',
      flex: 0.7,
      headerName: '',
      renderCell: (params: any) => {
        if (params.row?.isEditable) {
          return (
            <Box
              sx={{
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Visby CF Bold',
                  fontSize: '14px',
                  textDecoration: 'underline',
                  color: '#854693',
                }}
              >
                Təsdiq et
              </Typography>
            </Box>
          );
        } else {
          return <></>;
        }
      },
    });
  }

  return res;
};
