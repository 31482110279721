import React, { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import dayjs from "dayjs";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { log } from "node:console";
interface DatePickerProps {
  date: string;
  type: string;
  isOpen: boolean;
  handleDateChange: (
    date: string,
    type: string
  ) => void;
}
export default function DatePickerCustom(props: DatePickerProps) {
  const { date,type,isOpen, handleDateChange } = props;
  const [openDatePicker, setOpenDatePicker] = useState(false);
  return (
    <div style={{position: 'relative'}}>
      <TextField
        sx={{
          padding:"16px",
          "& .MuiOutlinedInput-input": {
            padding: 0,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
            "&.Mui-focused": {
              border: "none",
            },
          },
          "& .MuiInputBase-root": {
            fontSize: "14px",
            fontFamily: 'Visby CF Bold, sans-serif',
          },
        }}
       
        onClick={() => setOpenDatePicker(true)}
        value={date ? dayjs(date).format("DD MMMM YYYY") : 'Tarixi seç'}
        disabled={isOpen}
      />
      {openDatePicker && (
        <LocalizationProvider dateAdapter={AdapterDayjs}
        >
          <DatePicker
            sx={{visibility: 'hidden', position: 'absolute', left: '0'}}
              open={openDatePicker}
              onClose={() => setOpenDatePicker(false)}
              onChange={(value) => {
                const formattedDate = dayjs(value).format("MM-DD-YYYY"); // Adjust format here
                handleDateChange(formattedDate, type);
              }}
              value={date ? dayjs(date) : undefined}
          />
        </LocalizationProvider>
        
      )}
    </div>
  );
}
