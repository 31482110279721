import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';

export default function Index({ children, createdDate }: any) {
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '0px',
        // gap: '10px',
        backgroundColor: 'white',
        width: '90%'
      }}
    >
      <Box sx={{ marginTop: '5px' }}>
        <Box>
          {children}

          <Box sx={{ alignSelf: 'stretch' }}>
            <span style={{ fontSize: '12px' }}>İcazə</span>
            <span style={{ fontFamily: 'Visby CF Bold', fontSize: '12px' }}>
              {' '}
              {moment(createdDate).format('DD MMM YYYY')}
            </span>
            <span style={{ fontSize: '12px' }}>
              {' '}
              tarxində əlavə edilmişdir.
            </span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
