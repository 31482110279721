import { Checkbox, TableCell, TableRow, Box, Typography } from "@mui/material";
import TableCellBody from "../../../../../../../../components/custom-components/table-cell-body";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { color, fontFamily } from "@mui/system";

interface ISetProgramCellModel{
    key: string,
    text?: any,
    isCustomelement?: boolean,
    customElement?: any,
    isFirst?: boolean,
    version?: string,
}

interface ISetProgramRowModel{
    cells: ISetProgramCellModel[],
    hasCheckBox: boolean,
    onCheckBoxChecked?: any,
    areAllSelected?: boolean,
    indeterminate?: boolean,
    onArrowClick?: any,
    isArrowClicked?: boolean,
    hasSubModules?: boolean,
    isChecked?: boolean,
    isDeletable?: boolean,
    onDelete?: any
}

export default function  Index({cells, hasCheckBox, isChecked = false, onCheckBoxChecked, areAllSelected = false, indeterminate = false, onArrowClick, hasSubModules= false, isArrowClicked = false, isDeletable, onDelete} : ISetProgramRowModel) {
  return (
    <TableRow>
        {
            hasCheckBox ? (
              <TableCell align="left" width={'3%'}>
              <Checkbox
                sx={{ padding: "0" }}
                checked={isChecked}
                indeterminate={indeterminate}
                onChange={onCheckBoxChecked}
              />
              </TableCell> 
            ) : null
        }
        {
            cells?.map((elem, index)=> (
                <TableCellBody sx={{py: 0,}} key={index} width="13%">
                    {
                        elem.isCustomelement ? elem.customElement : elem.text
                    }
                </TableCellBody>
            ))
        }
    </TableRow>
  )
}
