import Chip from "@mui/material/Chip";
import { Box, Typography} from "@mui/material";
interface KpiProps{
 value:any
 color:string
 tagTitle:string
 iconColor:string

}
export function Kpi(props:KpiProps) {
const{value,tagTitle,color,iconColor,}=props
  return (
    <Box
      sx={{
        position: "relative",
        objectFit: "cover",
        borderRadius: "16px",
        overflow: "hidden",
      }}
      height="80px"
      width="150px"
      border={`0.5px solid ${color}`}
    >
      <Typography
        sx={{
          position:"absolute",
          top:'12px',
          left:'16px',
          width: "100%",
          fontFamily: "Visby CF Bold,sans-serif",
          lineHeight: "24px",
          letterSpacing: "0.4px",
          fontSize:'24px',
        }}
      >
        {value}
      </Typography>
      <Chip
                
                label={`${tagTitle}`}
                sx={{
                  position:'absolute',
                  left:'16px',
                  bottom:'12px',
                  backgroundColor:color,
                  color: "#FFF",
                  letterSpacing: "0.4px",
                  height: "20px",
                  "& .css-6od3lo-MuiChip-label": {
                    fontSize:'8px',
                    fontFamily: "Visby CF Bold,sans-serif",
                  },
                }}
              />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 150 80"
        fill="none"
      >
        <path
          opacity="0.4"
          d="M25.7884 70.0618L0.968468 77.3571C0.394312 77.5258 0 78.0527 0 78.6512C0 79.3961 0.603902 80 1.34885 80H165C173.837 80 181 72.8366 181 64V36.9069C181 25.1965 166.364 19.8872 158.856 28.874C153.63 35.1297 143.916 34.8075 139.115 28.2192L133.515 20.5326C126.58 11.0151 112.087 11.9969 106.499 22.3627L96.3968 41.1037C92.0518 49.1639 81.8414 51.9405 74.013 47.1906L73.6347 46.9611C67.9171 43.4919 60.645 43.9449 55.4021 48.0967L31.2094 67.2545C29.5993 68.5295 27.7588 69.4826 25.7884 70.0618Z"
          fill={iconColor}
        />
      </svg>
    </Box>
  );
}
