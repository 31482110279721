import {
    useDelete,
      useFetch, usePost, useUpdate,
  } from '../utils/api/reactQuery';
  import { apiRoutes } from '../apiRoutes';

  export const useGetAttendanceWorkersList = (id: number) =>
  useFetch<any>(apiRoutes.attendances.workers(id), undefined, [apiRoutes.attendances.workers(id), undefined]);

  export const useStartAttendance = (
    id: number,
    updater: (oldData: any, newData: any) => any) => 
    usePost<any, any>(apiRoutes.classes.startLesson(id), undefined, updater);

  export const useEndAttendance = (
    classTimeSheetId: number,
    updater: (oldData: any, newData: any) => any) => 
    usePost<any, any>(apiRoutes.classTimeSheets.attendanceEnd(classTimeSheetId), undefined, updater);

  export const useSaveAttendance = (
      classTimeSheetId: number,
      updater: (oldData: any, newData: any) => any) => 
      usePost<any, any>(apiRoutes.classTimeSheets.attendance(classTimeSheetId), undefined, updater);

  export const useJoinClass = (
    classTimeSheetId: number,
    updater: (oldData: any, newData: any) => any) => 
    usePost<any, any>(apiRoutes.classTimeSheets.join(classTimeSheetId), undefined, updater);