import { Table, TableContainer, TableBody } from "@mui/material";
import { Fragment } from "react";
import Header from "./header";
import Row from "./row";
import CustomDatePicker from "../date-picker";
import CustomMultipleAutocomplete from "../../../../../../../components/custom-components/custom-multuple-autocomplete";
import { usePostTotalHoursByDate } from "../../../../../../../api/classes";
import dayjs from "dayjs";

interface ISetProgramTableModel {
  generationStartDate: any;
  modules: any;
  setModules: any;
  customSX?: any;
  setLoading: any;
  classId?: any;
  headerCells: any;
  hasCheckBox: boolean;
}
export default function Index({
  modules,
  classId,
  setModules,
  customSX,
  headerCells,
  hasCheckBox,
  generationStartDate,
}: ISetProgramTableModel) {
  const mutation = usePostTotalHoursByDate(classId);
  const handleStartAndEndDateChange = async (
    newDate: any,
    key: any,
    startDate?: any,
    endDate?: any,
    workerType?: any
  ) => {
    try {
      // 1. Modülleri güncelle
      const updatedModules = modules.map((elem: any) => {
        if (
          elem.startDate === startDate &&
          elem.endDate === endDate &&
          elem.workerType === workerType
        ) {
          return { ...elem, [key]: newDate, totalHours: 0 };
        }
        return elem;
      });
  
      // 2. `startDate` değişiminde önceki modülü bul ve `endDate`'ini güncelle.
      if (key === "startDate") {
        const previousModule = modules.find(
          (elem: any) =>
            elem.workerType === workerType &&
            elem.endDate === dayjs(startDate).subtract(1, "day").format("MM-DD-YYYY")
        );
  
        if (previousModule) {
          const adjustedModules = updatedModules.map((elem: any) => {
            if (
              elem.endDate === previousModule.endDate &&
              elem.workerType === workerType
            ) {
              return {
                ...elem,
                endDate: dayjs(newDate).subtract(1, "day").format("MM-DD-YYYY"),
              };
            }
            return elem;
          });
  
          await updateModules(adjustedModules);
          return; // Yeni modül eklenmeden işlem tamamlandı.
        }
      }
  
      // 3. `endDate` değişiminde bir sonraki modülü bul ve `startDate`'ini güncelle.
      if (key === "endDate") {
        const nextModule = modules.find(
          (elem: any) =>
            elem.workerType === workerType &&
            elem.startDate === dayjs(endDate).add(1, "day").format("MM-DD-YYYY")
        );
  
        if (nextModule) {
          const adjustedModules = updatedModules.map((elem: any) => {
            if (
              elem.startDate === nextModule.startDate &&
              elem.workerType === workerType
            ) {
              return {
                ...elem,
                startDate: dayjs(newDate).add(1, "day").format("MM-DD-YYYY"),
              };
            }
            return elem;
          });
  
          await updateModules(adjustedModules);
          return; // Yeni modül eklenmeden işlem tamamlandı.
        }
      }
  
      // 4. Yeni modül oluştur ve ekle
      const newStartDate = dayjs(newDate).add(1, "day").format("MM-DD-YYYY");
      const newModule = {
        startDate: newStartDate,
        endDate: endDate,
        workerType,
        totalHours: 0,
        workers: [],
      };
  
      const finalModules = [newModule, ...updatedModules];
      await updateModules(finalModules);
    } catch (error) {
      console.error("Mutation error:", error);
    }
  };
  
  const updateModules = async (modules: any[]) => {
    // API'ye mutasyon için modülleri gönder.
    const datesArray = modules.map((module: any) => ({
      startDate: module.startDate,
      endDate: module.endDate,
    }));
  
    const response = await mutation.mutateAsync(datesArray);
  
    // Modülleri state'e kaydet
    setModules(() =>
      response.data?.map((module: any) => {
        const existingModule = modules.find(
          (elem: any) =>
            elem.startDate === module.startDate && elem.endDate === module.endDate
        );
        return {
          ...existingModule,
          totalHours: module.totalHours,
        };
      })
    );
  };
  

  const handleTeacherAndMentorChange = (
    selectedWorkers: any[], // Dropdown'da seçilen kullanıcılar
    startDate?: any,
    endDate?: any,
    workerType?: any
  ) => {
    setModules((prevModules: any) =>
      prevModules.map((module: any) => {
        if (
          module.startDate === startDate &&
          module.endDate === endDate &&
          module.workerType === workerType
        ) {
          return {
            ...module,
            workers: selectedWorkers, // Yalnızca yeni seçilen kullanıcıları ayarla
          };
        }
        return module;
      })
    );
  };
  return (
    <TableContainer sx={customSX}>
      <Table
        sx={{
          width: "100%",
          border: "none",
        }}
        aria-label="simple table"
      >
        <Header cells={headerCells} />
        <TableBody>
          {modules &&
            modules.map((module: any, index: any) => (
              <Fragment key={index}>
                <Row
                  hasCheckBox={hasCheckBox}
                  cells={headerCells.map((key: any, index: number) => {
                    if (key.key === "startDate" || key.key === "endDate") {
                      return {
                        key: key.key,
                        text: module[key?.key],
                        isCustomelement: true,
                        customElement: (
                          <CustomDatePicker
                            generationStartDate={generationStartDate}
                            isOpen={
                              key.key === "startDate"
                                ? module.startDate === generationStartDate
                                : false
                            }
                            date={module[key?.key]}
                            handleDateChange={(e) =>
                              handleStartAndEndDateChange(
                                e,
                                key.key,
                                module.startDate,
                                module.endDate,
                                module.workerType
                              )
                            }
                            type="date"
                          />
                        ),
                      };
                    } else if (key.key === "workers") {
                      return {
                        key: key.key,
                        isCustomelement: true,
                        customElement: (
                          <CustomMultipleAutocomplete
                            workerType={module.workerType || []}
                            value={module.workers} // Pass the array of selected workers
                            label={""}
                            url={`Workers?workerType=${module.workerType}`}
                            propName="name"
                            onChange={(selectedWorkers: any[]) =>
                              handleTeacherAndMentorChange(
                                selectedWorkers,
                                module.startDate,
                                module.endDate,
                                module.workerType
                              )
                            }
                          />
                        ),
                      };
                    }
                    return {
                      key: key.key,
                      text: module[key?.key],
                    };
                  })}
                />
              </Fragment>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
